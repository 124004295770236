import React from "react";
import Html from "./Html";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import Router from "./router";

// Library Styles

import "./styles/App.scss";
import 'redux-blogs/build/index.css';
import 'dg-forms/build/index.css';
import "dg-cms/build/index.css";
import "dg-admin/build/index.css";
import "dg-utils/build/index.css";
import "dg-analytics/build/index.css";
import "./components/sweetAlert2/modal.scss"
import "dg-cookies/build/index.css";

// Global Styles
import "./components/dg-cookie/dg-cookie.scss";

// Define the initial state
const preloadedState = store.getState();

function App({ assets, helmet, server, currentLocation }) {
    return (
        <Provider store={store}>
            {server ? (
                <Html
                    assets={assets}
                    helmet={helmet}
                    preloadedState={preloadedState}
                    server
                >
                    <StaticRouter location={currentLocation}>
                        <Router />
                    </StaticRouter>
                </Html>
            ) : (
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            )}
        </Provider>
    );
}

export default App;
