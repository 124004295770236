import { SET_IS_BENEFITS_OPEN, SET_BENEFIT } from "../actions/types";

//Currently using default benefit to ensure sizing of left container card is consistent. There has got to be a much cleaner way of doing this. Using min-width could mess up responsivity. I will pray to the god of js for many years in penance for this sin.

const initialState = {
  isBenefitsOpen: false,
  benefit: {
    CMSSection: "Policy Benefits",
    title: "Policy Benefits",
    paragraphs: [
      "As1 each car insurance policy is different, insurance companies will have a list of various car insurance benefits and within these benefits the level of cover will also vary! For example, with Breakdown Assistance some insurance companies may also include Home Start Cover at no extra charge. ",
    ],
    index: 0,
    default: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BENEFIT:
      return {
        ...state,
        benefit: action.payload,
      };
    case SET_IS_BENEFITS_OPEN:
      return {
        ...state,
        isBenefitsOpen: action.payload,
      };
    default:
      return state;
  }
}
