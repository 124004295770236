import {
    CREATE_DRUG_INTERACTION,
    UPDATE_DRUG_INTERACTION,
    DELETE_DRUG_INTERACTION,
    GET_DRUG_INTERACTION,
    GET_ALL_DRUG_INTERACTION,
    SET_CURRENT_DRUG_INTERACTION,
    GET_PATIENT_DRUG_INTERACTIONS,
} from "../../actions/types";

const initialState = {
    currentInteraction: {},
    interactions: [],
    patientInteractions: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_DRUG_INTERACTION:
        case CREATE_DRUG_INTERACTION:
        case UPDATE_DRUG_INTERACTION:
        case GET_DRUG_INTERACTION:
            return {
                ...state,
                currentInteraction: action.payload,
            };
        case DELETE_DRUG_INTERACTION:
            return {
                ...state,
                currentInteraction: {},
            };
        case GET_ALL_DRUG_INTERACTION:
            return {
                ...state,
                interactions: action.payload,
            };
        case GET_PATIENT_DRUG_INTERACTIONS:
            return {
                ...state,
                patientInteractions: action.payload,
            };
        default:
            return state;
    }
}
