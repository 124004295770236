export const routes = [
    {
      url: '/admin/user',
      text: 'Users'
    },
    {
      url: '/admin/clinic',
      text: 'Reports'
    },
    {
      url: '/admin/user',
      text: 'Workflows'
    },
    {
      url: '/admin/user',
      text: 'Reports'
    },
    {
      url: '/admin/user',
      text: 'Accounting'
    },
    {
      url: '/admin/clinic',
      text: 'Clinics'
    },
];
