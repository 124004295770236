import {
    CREATE_PATIENT,
    UPDATE_PATIENT,
    DELETE_PATIENT,
    GET_PATIENT,
    GET_PATIENT_MEDICAL_HISTORY,
    GET_ALL_PATIENT,
    SET_CURRENT_PATIENT,
} from "../actions/types";

const initialState = {
    currentPatient: {},
    medicalHistory: {},
    patients: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_PATIENT:
        case CREATE_PATIENT:
        case UPDATE_PATIENT:
        case GET_PATIENT:
            return {
                ...state,
                currentPatient: action.payload,
            };
        case GET_PATIENT_MEDICAL_HISTORY:
            return {
                ...state,
                medicalHistory: action.payload,
            };
        case DELETE_PATIENT:
            return {
                ...state,
                currentPatient: {},
            };
        case GET_ALL_PATIENT:
            return {
                ...state,
                patients: action.payload,
            };
        default:
            return state;
    }
}
