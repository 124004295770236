import React, { useEffect } from "react";

import "./style.scss";
import { connect } from "react-redux";

function LoadingOverlay(props) {
  useEffect(() => {
    if (props.isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.isLoading]);

  if (props.isLoading)
    return (
      <div className="loading-overlay">
        <span className="loader"></span>
        <p>Loading, please wait...</p>
      </div>
    );
}

const mapStateToProps = (state) => ({
  isLoading: state.utility.isLoading,
});

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingOverlay);
