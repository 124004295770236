import React from "react";
import loading from "../../assets/icons/loading.gif";

// Assets

const Loading = (props) => (
    <div
        style={{
            height: "100vh",
            flex: "1 1 100%",
            justifyContent: "center",
            alignItems: "center",
        }}
        className="container-row center"
    >
        <div className="item">
            <img
                src={loading}
                alt="We Quote, You Drive"
                style={{ height: "200px", width: "200px" }}
            />
        </div>
    </div>
);

export default Loading;
