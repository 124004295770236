import {
  CREATE_IMMUNIZATION_ROUTE,
  UPDATE_IMMUNIZATION_ROUTE,
  DELETE_IMMUNIZATION_ROUTE,
  GET_IMMUNIZATION_ROUTE,
  GET_ALL_IMMUNIZATION_ROUTE,
  SET_CURRENT_IMMUNIZATION_ROUTE,
} from "../actions/types";

const initialState = {
  currentImmunizationRoute: {},
  immunizationRoutes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_IMMUNIZATION_ROUTE:
    case CREATE_IMMUNIZATION_ROUTE:
    case UPDATE_IMMUNIZATION_ROUTE:
    case GET_IMMUNIZATION_ROUTE:
      return {
        ...state,
        currentImmunizationRoute: action.payload,
      };
    case DELETE_IMMUNIZATION_ROUTE:
      return {
        ...state,
        currentImmunizationRoute: {},
      };
    case GET_ALL_IMMUNIZATION_ROUTE:
      return {
        ...state,
        immunizationRoutes: action.payload,
      };
    default:
      return state;
  }
}
