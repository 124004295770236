import {
    CREATE_CLINIC,
    UPDATE_CLINIC,
    DELETE_CLINIC,
    GET_CLINIC,
    GET_ALL_CLINIC,
    SET_CURRENT_CLINIC,
} from "../actions/types";

const initialState = {
    currentClinic: {},
    clinics: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_CLINIC:
        case CREATE_CLINIC:
        case UPDATE_CLINIC:
        case GET_CLINIC:
            return {
                ...state,
                currentClinic: action.payload,
            };
        case DELETE_CLINIC:
            return {
                ...state,
                currentClinic: {},
            };
        case GET_ALL_CLINIC:
            return {
                ...state,
                clinics: action.payload,
            };
        default:
            return state;
    }
}
