import {
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENT,
  GET_ALL_APPOINTMENT,
  SET_CURRENT_APPOINTMENT,
  GET_ALL_GROUPED_APPOINTMENT,
} from "../actions/types";

const initialState = {
  currentAppointment: {},
  appointments: [],
  groupedAppointments: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_APPOINTMENT:
    case CREATE_APPOINTMENT:
    case UPDATE_APPOINTMENT:
    case GET_APPOINTMENT:
      return {
        ...state,
        currentAppointment: action.payload,
      };
    case DELETE_APPOINTMENT:
      return {
        ...state,
        currentAppointment: {},
      };
    case GET_ALL_APPOINTMENT:
      return {
        ...state,
        appointments: action.payload,
      };
    case GET_ALL_GROUPED_APPOINTMENT:
      return {
        ...state,
        groupedAppointments: action.payload,
      };
    default:
      return state;
  }
}
