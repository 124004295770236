import React, { lazy, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";
// Styles
import "./style.scss";

// Actions
import setIsOpen from "../../actions/modal/setIsOpen";
import setName from "../../actions/modal/setName";
import setData from "../../actions/modal/setData";

// Components
const QuoteAssumptions = lazy(() => import("../assumptionsPopup/component"));

function ComponentFn(props) {
    const { search } = useLocation();

    useEffect(() => {
        Modal.setAppElement("body");
    }, []);

    useEffect(() => {
        // Check if a modal should be open by default
        if (search.includes("modal=open") && decodeURIComponent(search).includes("modalName=")) {
            // Set the modal which should be open
            props.setName(decodeURIComponent(search).split("modalName=").pop().split("&")[0]);
            // Open the modal
            props.setIsOpen(true);
        }
    }, []);

    // Determine which modal to show
    const Content = useMemo(() => {
        switch (props.name) {
            case "quoteAssumptions":
                return <DgLazyComponent Component={QuoteAssumptions} />;
            default:
                return null;
        }
    }, [props.name]);

    // Prevent background scrolling when the modal is open
    // Reference: https://stackoverflow.com/a/58290739
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
        return () => (document.body.style.overflow = "unset");
    }, [props.isOpen]);

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={() => {
                props.setIsOpen(false);
                props.setData({});
            }}
            contentLabel=""
            className="modal-container"
            overlayClassName="modal-overlay-container"
        >
            <div className="modal-content">{Content}</div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    isOpen: state.modal?.isOpen,
    name: state.modal?.name,
});

const mapDispatchToProps = (dispatch) => ({
    setIsOpen: (status) => dispatch(setIsOpen(status)),
    setName: (name) => dispatch(setName(name)),
    setData: (name) => dispatch(setData(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentFn);
