import {
  CREATE_MEDICATION_BATCH,
  UPDATE_MEDICATION_BATCH,
  DELETE_MEDICATION_BATCH,
  GET_MEDICATION_BATCH,
  GET_ALL_MEDICATION_BATCH,
  SET_CURRENT_MEDICATION_BATCH,
  RESET_MEDICATION_BATCH,
} from "../actions/types";

const initialState = {
  currentMedicationBatch: {},
  medicationBatches: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_MEDICATION_BATCH:
    case CREATE_MEDICATION_BATCH:
    case UPDATE_MEDICATION_BATCH:
    case GET_MEDICATION_BATCH:
      return {
        ...state,
        currentMedicationBatch: action.payload,
      };
    case DELETE_MEDICATION_BATCH:
      return {
        ...state,
        currentMedicationBatch: {},
      };
    case GET_ALL_MEDICATION_BATCH:
      return {
        ...state,
        medicationBatches: action.payload,
      };
    case RESET_MEDICATION_BATCH:
      return {
        currentMedicationBatch: {},
        medicationBatches: [],
      };
    default:
      return state;
  }
}
