import {
    CREATE_OBSERVATION,
    UPDATE_OBSERVATION,
    DELETE_OBSERVATION,
    GET_OBSERVATION,
    GET_ALL_OBSERVATION,
    SET_CURRENT_OBSERVATION,
} from "../actions/types";

const initialState = {
    currentObservation: {},
    observations: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_OBSERVATION:
        case CREATE_OBSERVATION:
        case UPDATE_OBSERVATION:
        case GET_OBSERVATION:
            return {
                ...state,
                currentObservation: action.payload,
            };
        case DELETE_OBSERVATION:
            return {
                ...state,
                currentObservation: {},
            };
        case GET_ALL_OBSERVATION:
            return {
                ...state,
                observations: action.payload,
            };
        default:
            return state;
    }
}
