import {
  CREATE_VIDEO_CONSULTATION_AVAILABILITY,
  UPDATE_VIDEO_CONSULTATION_AVAILABILITY,
  DELETE_VIDEO_CONSULTATION_AVAILABILITY,
  GET_VIDEO_CONSULTATION_AVAILABILITY,
  GET_ALL_VIDEO_CONSULTATION_AVAILABILITY,
  SET_CURRENT_VIDEO_CONSULTATION_AVAILABILITY,
  GET_NEAREST_VIDEO_CONSULTATION_AVAILABILITY,
} from "../actions/types";

const initialState = {
  currentVideoConsultationAvailability: {},
  videoConsultationsAvailabilities: [],
  nearestAvailableDate:''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VIDEO_CONSULTATION_AVAILABILITY:
    case CREATE_VIDEO_CONSULTATION_AVAILABILITY:
    case UPDATE_VIDEO_CONSULTATION_AVAILABILITY:
    case GET_VIDEO_CONSULTATION_AVAILABILITY:
      return {
        ...state,
        currentVideoConsultationAvailability: action.payload,
      };
    case DELETE_VIDEO_CONSULTATION_AVAILABILITY:
      return {
        ...state,
        currentVideoConsultationAvailability: {},
      };
    case GET_ALL_VIDEO_CONSULTATION_AVAILABILITY:
      return {
        ...state,
        currentVideoConsultationAvailability: {},
        videoConsultationsAvailabilities: action.payload,
      };
    case GET_NEAREST_VIDEO_CONSULTATION_AVAILABILITY:
      return {
        ...state,
        currentVideoConsultationAvailability: {},
        videoConsultationsAvailabilities: action.payload.availabilities,
        nearestAvailableDate:action.payload.nearestAvailableDate
      };
    default:
      return state;
  }
}
