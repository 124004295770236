import {
    CREATE_ALLERGY_INTOLERANCE,
    UPDATE_ALLERGY_INTOLERANCE,
    DELETE_ALLERGY_INTOLERANCE,
    GET_ALLERGY_INTOLERANCE,
    GET_ALL_ALLERGY_INTOLERANCE,
    SET_CURRENT_ALLERGY_INTOLERANCE,
} from "../actions/types";

const initialState = {
    currentAllergyIntolerance: {},
    allergyIntolerances: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_ALLERGY_INTOLERANCE:
        case CREATE_ALLERGY_INTOLERANCE:
        case UPDATE_ALLERGY_INTOLERANCE:
        case GET_ALLERGY_INTOLERANCE:
            return {
                ...state,
                currentAllergyIntolerance: action.payload,
            };
        case DELETE_ALLERGY_INTOLERANCE:
            return {
                ...state,
                currentAllergyIntolerance: {},
            };
        case GET_ALL_ALLERGY_INTOLERANCE:
            return {
                ...state,
                allergyIntolerances: action.payload,
            };
        default:
            return state;
    }
}
