import { 
    DELETE_POLICY_CHANGE, 
    GET_POLICY_CHANGES, 
    GET_POLICY_CHANGE,
    POLICY_CHANGE_SUBMIT
  } from '../actions/types';
  
  const initialState = {
    policychange: {},
    policychanges: [],
    currentPolicyChange: {},
    ok: true,
    message: '',
    errors: []
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case POLICY_CHANGE_SUBMIT:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          policyChange: action.payload.policyChange
        }
      case GET_POLICY_CHANGE:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          currentPolicyChange: action.payload.policyChange
        }
      case GET_POLICY_CHANGES:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          policyChanges: action.payload.policyChanges
        }
      case DELETE_POLICY_CHANGE:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          currentPolicyChange: action.payload.policyChange
        }
      default:
        return state
    }
  }