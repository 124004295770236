import { UPDATE_IS_MOBILE_SITE } from "../actions/types";
import { TOGGLE_MOBILE_MENU } from "../actions/types";

//Include mobile dimensions breakpoint here
const initialValues = {
    mobileMenuOpen: false,
    isMobileSite: false,
    mobileBreakpoint: 1024,
};

export default function (state = initialValues, action) {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                mobileMenuOpen: !state.mobileMenuOpen,
            };
        case UPDATE_IS_MOBILE_SITE:
            return {
                ...state,
                isMobileSite: action.payload,
            };
        default:
            return state;
    }
}
