import {
  CREATE_VACCINE,
  UPDATE_VACCINE,
  DELETE_VACCINE,
  GET_VACCINE,
  GET_ALL_VACCINE,
  SET_CURRENT_VACCINE,
} from "../actions/types";

const initialState = {
  currentVaccine: {},
  vaccines: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VACCINE:
    case CREATE_VACCINE:
    case UPDATE_VACCINE:
    case GET_VACCINE:
      return {
        ...state,
        currentVaccine: action.payload,
      };
    case DELETE_VACCINE:
      return {
        ...state,
        currentVaccine: {},
      };
    case GET_ALL_VACCINE:
          return {
        ...state,
        vaccines: action.payload,
      };
    default:
      return state;
  }
}
