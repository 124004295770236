import { SET_IS_MODAL_OPEN } from "../types";

const actionFn = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_IS_MODAL_OPEN,
            payload: data,
        });

        return data;
    } catch (err) {
        throw err;
    }
};

export default actionFn;
