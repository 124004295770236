import {
    SET_QUOTE_FORM,
    QUOTE_CREATE_FAILED,
    QUOTE_CREATE_SUCCESS,
    QUOTE_UPDATE_FAILED,
    QUOTE_UPDATE_SUCCESS,
    QUOTE_SUBMIT_FAILED,
    QUOTE_SUBMIT_SUCCESS,
    QUOTE_GET_FAILED,
    QUOTE_GET_SUCCESS,
    VEHICLE_LOOKUP_FAILED,
    VEHICLE_LOOKUP_SUCCESS,
    SET_SUMMARY_ISHIDDEN,
    POLICY_CHANGE_FAILED,
    POLICY_CHANGE_SUCCESS,
    ADDRESS_LOOKUP_FAILED,
    ADDRESS_LOOKUP_SUCCESS,
    SET_CURRENT_ADDRESS,
    SET_CONFIRMED_VEHICLE,
    AREA_LOOKUP_FAILED,
    AREA_LOOKUP_SUCCESS,
    COUNTY_LOOKUP_SUCCESS,
    SET_CURRENT_QUOTE,
    SET_VEHICLE_DETAILS_SHOWING,
    SET_GET_A_QUOTE_INITIAL_VALUES,
    SET_IS_VEHICLE_LOADING,
    SET_IS_QUOTE_LOADING,
    SET_IS_VEHICLE_LOOKUP,
    QUOTE_GET_BY_WEBREFERENCE_SUCCESS,
    QUOTE_GET_BY_WEBREFERENCE_FAILED,
    CLEAR_VEHICLE_LOOKUP,
    OCCUPATION_LOOKUP_SUCCESS,
    OCCUPATION_LOOKUP_FAILURE,
    SET_CURRENT_OCCUPATION,
    BUSINESSTYPE_LOOKUP_SUCCESS,
    BUSINESSTYPE_LOOKUP_FAILURE,
    SET_CURRENT_BUSINESSTYPE,
    SET_RETRIEVE_A_QUOTE_INITIAL_VALUES,
    SEND_TO_APPLIED_RELAY,
    CREATE_AND_SEND_DOCUMENTS,
} from "../actions/types";

const initialQuoteForm = {
    your_details: {
        gender: "F",
    },
    your_car: {
        isVehicleConfirmed: false,
    },
    your_driving_history: {
        most_recent_insurance: [{}],
    },
    additional_drivers: [{}],
    claims: {
        claims: [{}],
    },
};

const initialRetrieveQuoteForm = {
    webReference: "",
};

const initialState = {
    quote: {},
    isQuoteLoading: false,
    policy: {},
    currentQuote: {},
    addresses: [],
    areas: [],
    counties: [],
    currentAddress: "",
    isVehicleConfirmed: false,
    isVehicleDetailsShowing: false,
    isVehicleLoading: false,
    isVehicleLookupSubmitted: false,
    vehicleLookupType: "REG",
    summaryIsHidden: [true, true, true, true, true, true],
    page: 1,
    ok: true,
    message: "",
    errors: [],
    getAQuoteInitial: initialQuoteForm,
    retrieveAQuoteInitial: initialRetrieveQuoteForm,
    occupations: [],
    currentOccupation: "",
    businessTypes: [],
    currentBusinessType: "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_AND_SEND_DOCUMENTS:
        case SEND_TO_APPLIED_RELAY:
            return {
                ...state,
                currentQuote: action.payload,
            };
        case CLEAR_VEHICLE_LOOKUP:
            return {
                ...state,
                isVehicleConfirmed: false,
                isVehicleDetailsShowing: false,
                isVehicleLoading: false,
                isVehicleLookupSubmitted: false,
                vehicleLookupType: "REG",
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
                errors: action.payload.errors,
            };
        case QUOTE_CREATE_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case QUOTE_CREATE_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case QUOTE_UPDATE_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
            };
        case QUOTE_UPDATE_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case QUOTE_SUBMIT_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case QUOTE_SUBMIT_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case QUOTE_GET_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
                areas: action.payload.areas,
                isQuoteLoading: false,
                isVehicleLookupSubmitted: true,
            };
        case QUOTE_GET_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                isQuoteLoading: false,
                isVehicleLookupSubmitted: true,
            };
        case QUOTE_GET_BY_WEBREFERENCE_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case QUOTE_GET_BY_WEBREFERENCE_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                isQuoteLoading: false,
            };
        case VEHICLE_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                currentQuote: action.payload.quote,
                message: action.payload.message,
            };
        case VEHICLE_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case SET_QUOTE_FORM:
            return {
                ...state,
                page: action.payload,
            };
        case SET_SUMMARY_ISHIDDEN:
            return {
                ...state,
                summaryIsHidden: action.payload,
            };
        case POLICY_CHANGE_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                policy: action.payload.policy,
                message: action.payload.message,
            };
        case POLICY_CHANGE_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case ADDRESS_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                addresses: action.payload.addresses,
                message: action.payload.message,
            };
        case ADDRESS_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case SET_CURRENT_ADDRESS:
            return {
                ...state,
                currentAddress: action.payload,
                addresses: [],
            };
        case SET_CONFIRMED_VEHICLE:
            return {
                ...state,
                isVehicleConfirmed: action.payload.isVehicleConfirmed,
                vehicleLookupType: action.payload.vehicleLookupType,
            };
        case AREA_LOOKUP_SUCCESS:
            return {
                ...state,
                areas: action.payload,
            };
        case COUNTY_LOOKUP_SUCCESS:
            return {
                ...state,
                counties: action.payload,
            };
        case AREA_LOOKUP_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case SET_CURRENT_QUOTE:
            return {
                ...state,
                currentQuote: action.payload,
            };
        case SET_VEHICLE_DETAILS_SHOWING:
            return {
                ...state,
                isVehicleDetailsShowing: action.payload,
            };
        case SET_GET_A_QUOTE_INITIAL_VALUES:
            return {
                ...state,
                getAQuoteInitial: action.payload,
            };
        case SET_RETRIEVE_A_QUOTE_INITIAL_VALUES:
            return {
                ...state,
                retrieveAQuoteInitial: action.payload,
            };
        case SET_IS_VEHICLE_LOADING:
            return {
                ...state,
                isVehicleLoading: action.payload,
            };
        case SET_IS_VEHICLE_LOOKUP:
            return {
                ...state,
                isVehicleLookupSubmitted: action.payload,
            };
        case SET_IS_QUOTE_LOADING:
            return {
                ...state,
                isQuoteLoading: action.payload,
            };
        case OCCUPATION_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                occupations: action.payload.occupations,
                message: action.payload.message,
            };
        case OCCUPATION_LOOKUP_FAILURE:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case SET_CURRENT_OCCUPATION:
            return {
                ...state,
                currentOccupation: action.payload,
                occupations: [],
            };
        case BUSINESSTYPE_LOOKUP_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                businessTypes: action.payload.businessTypes,
                message: action.payload.message,
            };
        case BUSINESSTYPE_LOOKUP_FAILURE:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
            };
        case SET_CURRENT_BUSINESSTYPE:
            return {
                ...state,
                currentBusinessType: action.payload,
                businessTypes: [],
            };
        default:
            return state;
    }
}
