import { SET_MODAL_DATA } from "../types";

const actionFn = (data) => async (dispatch) => {
    try {
        dispatch({
            type: SET_MODAL_DATA,
            payload: data,
        });

        return data;
    } catch (err) {
        throw err;
    }
};

export default actionFn;
