import {
  CREATE_VISIT_TEMPLATE,
  UPDATE_VISIT_TEMPLATE,
  DELETE_VISIT_TEMPLATE,
  GET_VISIT_TEMPLATE,
  GET_ALL_VISIT_TEMPLATE,
  SET_CURRENT_VISIT_TEMPLATE,
} from "../actions/types";

const initialState = {
  currentVisitTemplate: {},
  visitTemplates: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VISIT_TEMPLATE:
    case CREATE_VISIT_TEMPLATE:
    case UPDATE_VISIT_TEMPLATE:
    case GET_VISIT_TEMPLATE:
      return {
        ...state,
        currentVisitTemplate: action.payload,
      };
    case DELETE_VISIT_TEMPLATE:
      return {
        ...state,
        currentVisitTemplate: {},
      };
    case GET_ALL_VISIT_TEMPLATE:
      return {
        ...state,
        visitTemplates: action.payload,
      };
    default:
      return state;
  }
}
