export const routes = [
    {
      url: '/admin/user',
      text: 'Users'
    },
    {
      url: '/admin/posts',
      text: 'Blogs',
    },
    {
      url: '/admin/contact',
      text: 'Contacts'
    },
    {
      url: '/admin/quotes',
      text: 'Quotes'
    },
    {
      url: '/admin/policy',
      text: 'Policy'
    },
    {
        url: '/admin/renewals',
        text: 'Renewals'
    },
    {
      url: '/admin/newsletter',
      text: 'Newsletter'
    },
    {
      url: '/admin/cms',
      text: 'CMS'
    }

];
