// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  flex: 1 1;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden; }
  .loading-overlay .loader {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background: #005758;
    animation: wave 1s ease-in infinite; }
  .loading-overlay p {
    color: #005758;
    font-size: 24px;
    margin-top: 100px;
    font-weight: bold; }

@keyframes wave {
  0% {
    box-shadow: 0 0 0 0px #005758, 0 0 0 10px rgba(0, 87, 88, 0.2), 0 0 0 30px rgba(0, 87, 88, 0.6), 0 0 0 50px rgba(0, 87, 88, 0.4), 0 0 0 70px rgba(0, 87, 88, 0.2); }
  100% {
    box-shadow: 0 0 0 70px rgba(0, 87, 88, 0), 0 0 0 50px rgba(0, 87, 88, 0.2), 0 0 0 30px rgba(0, 87, 88, 0.4), 0 0 0 10px rgba(0, 87, 88, 0.6), 0 0 0 0px #005758; } }
`, "",{"version":3,"sources":["webpack://./src/components/loading/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,SAAO;EACP,YAAY;EACZ,aAAa;EACb,oCAKC;EACD,2BAA2B;EAC3B,mCAAmC;EACnC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB,EAAA;EArBpB;IAwBI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,mCAAmC,EAAA;EA7BvC;IAiCI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB,EAAA;;AAGnB;EACE;IACE,iKAEiC,EAAA;EAEnC;IACE,+JAE8B,EAAA,EAAA","sourcesContent":[".loading-overlay {\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  flex: 1;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(\n    255,\n    255,\n    255,\n    0.2\n  ); // Adjust the color and opacity as needed\n  backdrop-filter: blur(10px); // Adjust the blur amount as needed\n  -webkit-backdrop-filter: blur(10px); // For Safari support\n  z-index: 9999;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  overflow-y: hidden;\n\n  .loader {\n    width: 8px;\n    height: 8px;\n    position: relative;\n    border-radius: 50%;\n    background: #005758;\n    animation: wave 1s ease-in infinite;\n  }\n\n  p {\n    color: #005758;\n    font-size: 24px;\n    margin-top: 100px;\n    font-weight: bold;\n  }\n\n  @keyframes wave {\n    0% {\n      box-shadow: 0 0 0 0px rgba(0, 87, 88, 1), 0 0 0 10px rgba(0, 87, 88, 0.2),\n        0 0 0 30px rgba(0, 87, 88, 0.6), 0 0 0 50px rgba(0, 87, 88, 0.4),\n        0 0 0 70px rgba(0, 87, 88, 0.2);\n    }\n    100% {\n      box-shadow: 0 0 0 70px rgba(0, 87, 88, 0), 0 0 0 50px rgba(0, 87, 88, 0.2),\n        0 0 0 30px rgba(0, 87, 88, 0.4), 0 0 0 10px rgba(0, 87, 88, 0.6),\n        0 0 0 0px rgba(0, 87, 88, 1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
