// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container {
  align-content: start; }
  .ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container .dg-cookie {
    background-color: #50BC9B;
    color: white; }
    .ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container .dg-cookie .item .btn-primary {
      background-color: #5765cd; }
    .ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container .dg-cookie .label.toggle input:checked + .toggle-control {
      background-color: #5765cd;
      border-color: #5765cd;
      color: white; }
    .ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container .dg-cookie .label.toggle .toggle-control .toggle-on,
    .ReactModalPortal .dg-cookie-overlay-container .dg-cookie-container .dg-cookie .label.toggle .toggle-control .toggle-off {
      top: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/dg-cookie/dg-cookie.scss","webpack://./src/styles/includes/settings.scss"],"names":[],"mappings":"AAEA;EAEQ,oBAAoB,EAAA;EAF5B;IAIY,yBCLK;IDML,YCJM,EAAA;IDDlB;MAQgB,yBCLK,EAAA;IDHrB;MAWgB,yBCRK;MDSL,qBCTK;MDUL,YCZE,EAAA;IDDlB;;MAiBgB,MAAM,EAAA","sourcesContent":["@import \"../../styles/includes/settings.scss\";\n\n.ReactModalPortal {\n    .dg-cookie-overlay-container .dg-cookie-container {\n        align-content: start;\n        .dg-cookie {\n            background-color: $primary;\n            color: $text-light;\n\n            .item .btn-primary {\n                background-color: $text-purple;\n            }\n            .label.toggle input:checked + .toggle-control {\n                background-color: $text-purple;\n                border-color: $text-purple;\n                color: $text-light;\n            }\n            .label.toggle .toggle-control .toggle-on,\n            .label.toggle .toggle-control .toggle-off {\n                top: 0;\n            }\n        }\n    }\n}\n","// Colors\n$primary: #50BC9B;\n$secondary: #F58123;\n$text-light: white;\n$text-dark: #454545;\n$text-purple: #5765cd;\n$text-purple-alt: #343b7d;\n$background-tile-1: #f5f5ff;\n$background-tile-2: #e4f9e9;\n$background-tile-3: #dadaff;\n$background-tile-4: #eff9e7;\n$select-text: #828282;     \n$select-background: #fbfbfb;\n$table-border: #e0e0e0;    \n$form-border: #67b9b2; \n$navbar-dark: #212121;\n$form-error: #b10303;\n$header-green: #73c57b;\n\n// Spacing\n$paragraph-spacer: 3rem;\n$block-spacer: 5rem;\n$block-spacer-mobile: 2rem;\n$paragraph-spacer-mobile: 1rem;\n\n // Breakpoints\n$phone: 320px;\n$tablet: 768px;\n$desktop: 1024px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
