import {
    CREATE_VOICE_RECOGNITION,
    UPDATE_VOICE_RECOGNITION,
    DELETE_VOICE_RECOGNITION,
    GET_VOICE_RECOGNITION,
    GET_ALL_VOICE_RECOGNITION,
    SET_CURRENT_VOICE_RECOGNITION,
    SET_CURRENT_SEARCH,
} from "../actions/types";

const initialState = {
    currentSearch: "",
    currentVoiceRecognition: {},
    voiceRecognitions: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_VOICE_RECOGNITION:
        case CREATE_VOICE_RECOGNITION:
        case UPDATE_VOICE_RECOGNITION:
        case GET_VOICE_RECOGNITION:
            return {
                ...state,
                currentVoiceRecognition: action.payload,
            };
        case DELETE_VOICE_RECOGNITION:
            return {
                ...state,
                currentVoiceRecognition: {},
            };
        case GET_ALL_VOICE_RECOGNITION:
            return {
                ...state,
                voiceRecognitions: action.payload,
            };
        case SET_CURRENT_SEARCH:
            return {
                ...state,
                currentSearch: action.payload,
            };
        default:
            return state;
    }
}
