import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  GET_ORGANIZATION,
  GET_ALL_ORGANIZATION,
  SET_CURRENT_ORGANIZATION,
} from "../actions/types";

const initialState = {
  currentOrganization: {},
  organizations: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
    case CREATE_ORGANIZATION:
    case UPDATE_ORGANIZATION:
    case GET_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload,
      };
    case DELETE_ORGANIZATION:
      return {
        ...state,
        currentOrganization: {},
      };
    case GET_ALL_ORGANIZATION:
          return {
        ...state,
        organizations: action.payload,
      };
    default:
      return state;
  }
}
