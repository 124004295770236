import {
    CREATE_ENCOUNTER,
    DELETE_ENCOUNTER,
    GET_ALL_ENCOUNTER,
    GET_ENCOUNTER,
    GET_ENCOUNTER_ENTRIES,
    GET_PATIENT_ENCOUNTER_DATES,
    SET_CURRENT_ENCOUNTER,
    SET_IN_PROGRESS_ENCOUNTER,
    UPDATE_ENCOUNTER,
} from "../actions/types";

const initialState = {
    currentEncounter: {},
    inProgressEncounter: {},
    encounters: [],
    encounterDates: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_ENCOUNTER:
        case CREATE_ENCOUNTER:
        case UPDATE_ENCOUNTER:
        case GET_ENCOUNTER:
        case GET_ENCOUNTER_ENTRIES:
            return {
                ...state,
                currentEncounter: action.payload,
            };
        case SET_IN_PROGRESS_ENCOUNTER:
            return {
                ...state,
                inProgressEncounter: action.payload,
            };
        case DELETE_ENCOUNTER:
            return {
                ...state,
                currentEncounter: {},
            };
        case GET_ALL_ENCOUNTER:
            return {
                ...state,
                encounters: action.payload,
            };
        case GET_PATIENT_ENCOUNTER_DATES:
            return {
                ...state,
                encounterDates: action.payload,
            };
        default:
            return state;
    }
}
