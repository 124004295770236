import React from "react";
import loading from "../../assets/icons/loading.gif";

// Assets
const Loading = (props) => (
    <div
        style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            flex: "1 1 100%",
            justifyContent: "center",
            alignItems: "center",
        }}
        className="container-row center"
    >
        <img
            src={loading}
            alt="Deluxe Enviro"
            style={{
                display: "flex",
                justifyContent: "center",
                height: "200px",
                width: "200px",
            }}
        />
    </div>
);

export default Loading;
