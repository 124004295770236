//Blogs
export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const SELECT_POST = "SELECT_POST";
export const CLEAR_POST_FORM = "CLEAR_POST_FORM";

// Newsletter
export const NEWSLETTER_SUBMIT = "NEWSLETTER_SUBMIT";
export const DELETE_NEWSLETTER = "DELETE_NEWSLETTER";
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTER = "GET_NEWSLETTER";

// POLICY_CHANGE
export const POLICY_CHANGE_SUBMIT = "POLICY_CHANGE_SUBMIT";
export const DELETE_POLICY_CHANGE = "DELETE_POLICY_CHANGE";
export const GET_POLICY_CHANGES = "GET_POLICY_CHANGES";
export const GET_POLICY_CHANGE = "GET_POLICY_CHANGE";

//Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

//Useful Benefits
export const SET_IS_BENEFITS_OPEN = "SET_IS_BENEFITS_OPEN";
export const SET_BENEFIT = "SET_BENEFIT";

//Policy Benefits
export const SET_IS_POLICY_BENEFITS_OPEN = "SET_IS_POLICY_BENEFITS_OPEN";
export const SET_POLICY_BENEFIT = "SET_POLICY_BENEFIT";

//Utility
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
export const UPDATE_IS_MOBILE_SITE = "UPDATE_IS_MOBILE_SITE";

// Renewal
export const RENEWAL_SUBMIT = "RENEWAL_SUBMIT";
export const GET_RENEWALS = "GET_RENEWALS";
export const PAYMENT_CREATE_RENEWAL = "PAYMENT_CREATE_RENEWAL";
export const SET_IS_LOADING_RENEWAL = "SET_IS_LOADING_RENEWAL";
export const SET_PAGE = "SET_PAGE";

// Get A Quote
export const SET_QUOTE_FORM = "SET_QUOTE_FORM";
export const QUOTE_CREATE_SUCCESS = "QUOTE_CREATE_SUCCESS";
export const QUOTE_CREATE_FAILED = "QUOTE_CREATE_FAILED";
export const QUOTE_UPDATE_SUCCESS = "QUOTE_UPDATE_SUCCESS";
export const QUOTE_UPDATE_FAILED = "QUOTE_UPDATE_FAILED";
export const QUOTE_SUBMIT_FAILED = "QUOTE_SUBMIT_FAILED";
export const QUOTE_SUBMIT_SUCCESS = "QUOTE_SUBMIT_SUCCESS";
export const QUOTE_GET_FAILED = "QUOTE_GET_FAILED";
export const QUOTE_GET_SUCCESS = "QUOTE_GET_SUCCESS";
export const VEHICLE_LOOKUP_SUCCESS = "VEHICLE_LOOKUP_SUCCESS";
export const VEHICLE_LOOKUP_FAILED = "VEHICLE_LOOKUP_FAILED";
export const SET_SUMMARY_ISHIDDEN = "SET_SUMMARY_ISHIDDEN";
export const POLICY_CHANGE_SUCCESS = "POLICY_CHANGE_SUCCESS";
export const POLICY_CHANGE_FAILED = "POLICY_CHANGE_FAILED";
export const ADDRESS_LOOKUP_SUCCESS = "ADDRESS_LOOKUP_SUCCESS";
export const ADDRESS_LOOKUP_FAILED = "ADDRESS_LOOKUP_FAILED";
export const SET_CURRENT_ADDRESS = "SET_CURRENT_ADDRESS";
export const SET_CONFIRMED_VEHICLE = "SET_CONFIRMED_VEHICLE";
export const AREA_LOOKUP_FAILED = "AREA_LOOKUP_FAILED";
export const COUNTY_LOOKUP_SUCCESS = "COUNTY_LOOKUP_SUCCESS";
export const AREA_LOOKUP_SUCCESS = "AREA_LOOKUP_SUCCESS";
export const SET_CURRENT_QUOTE = "SET_CURRENT_QUOTE";
export const SET_VEHICLE_DETAILS_SHOWING = "SET_VEHICLE_DETAILS_SHOWING";
export const SET_GET_A_QUOTE_INITIAL_VALUES = "SET_GET_A_QUOTE_INITIAL_VALUES";
export const SET_IS_VEHICLE_LOADING = "SET_IS_VEHICLE_LOADING";
export const SET_IS_QUOTE_LOADING = "SET_IS_QUOTE_LOADING";
export const SET_IS_VEHICLE_LOOKUP = "SET_IS_VEHICLE_LOOKUP";
export const QUOTE_GET_BY_WEBREFERENCE_FAILED = "QUOTE_GET_BY_WEBREFERENCE_FAILED";
export const QUOTE_GET_BY_WEBREFERENCE_SUCCESS = "QUOTE_GET_BY_WEBREFERENCE_SUCCESS";
export const CLEAR_VEHICLE_LOOKUP = "CLEAR_VEHICLE_LOOKUP";
export const OCCUPATION_LOOKUP_SUCCESS = "OCCUPATION_LOOKUP_SUCCESS";
export const OCCUPATION_LOOKUP_FAILURE = "OCCUPATION_LOOKUP_FAILURE";
export const SET_CURRENT_OCCUPATION = "SET_CURRENT_OCCUPATION";
export const BUSINESSTYPE_LOOKUP_SUCCESS = "BUSINESSTYPE_LOOKUP_SUCCESS";
export const BUSINESSTYPE_LOOKUP_FAILURE = "BUSINESSTYPE_LOOKUP_FAILURE";
export const SET_CURRENT_BUSINESSTYPE = "SET_CURRENT_BUSINESSTYPE";
export const SET_RETRIEVE_A_QUOTE_INITIAL_VALUES = "SET_RETRIEVE_A_QUOTE_INITIAL_VALUES";
export const SEND_TO_APPLIED_RELAY = "SEND_TO_APPLIED_RELAY"
export const CREATE_AND_SEND_DOCUMENTS = "CREATE_AND_SEND_DOCUMENTS"

// Payments
export const PAYMENT_CREATE_SUCCESS = "PAYMENT_CREATE_SUCCESS";
export const PAYMENT_CREATE_FAILED = "PAYMENT_CREATE_FAILED";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_PAYMENT_OPTION = "SET_PAYMENT_OPTION";

//Reviews
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";

// Modals
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_MODAL_NAME = "SET_MODAL_NAME";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const APPEND_MODAL_DATA = "APPEND_MODAL_DATA";
