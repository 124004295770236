export { reducer as form } from "redux-form";
export { default as appointment } from "./appointment"; // Appointment
export { default as users } from "dg-admin/build/userReducer.js"; // import the contactReducer from dg-admin
export { default as contacts } from "dg-admin/build/contactReducer.js"; // import the contactReducer from dg-admin
export { default as cms } from "dg-cms/build/reducer.js"; // import the cms reducer from dg-cms
export { default as diagnosticReport } from "./diagnosticReport"; // Diagnostic Report
export { default as documentReference } from "./documentReference"; // Document References
export { default as utility } from "./utility.reducer";
export { default as modal } from "./modal"; // Modals
export { default as allergyIntolerance } from "./allergyIntolerance"; // Allergy Intolerance
export { default as clinic } from "./clinic"; // Clinics
export { default as encounter } from "./encounter"; // Encounter
export { default as flowchart } from "./flowchart"; // Flowchart
export { default as immunization } from "./immunization"; // Immunization
export { default as immunizationRoute } from "./immunizationRoute"; // Immunization route
export { default as immunizationSite } from "./immunizationSite"; // Immunization site
export { default as location } from "./location"; // Locations
export { default as lookup } from "./lookup"; // Lookups
export { default as medication } from "./medication"; // Medication
export { default as medicationBatch } from "./medicationBatch"; // Medication
export { default as medicationRequest } from "./medicationRequest"; // Medication Request
export { default as observation } from "./observation"; // Observation
export { default as organization } from "./organization"; // Organization
export { default as patient } from "./patients"; // Patients
export { default as practitioner } from "./practitioner"; // Practitioner
export { default as practitionerRole } from "./practitionerRole"; // Practitioner Role
export { default as search } from "./search"; // Search
export { default as serviceRequest } from "./serviceRequest"; // Service Request
export { default as staff } from "./staff"; // Staff
export { default as drugInteraction } from "./drug/interaction"; // Drug Interactions
export { default as drugProduct } from "./drug/product"; // Drug Products
export { default as drugWarning } from "./drug/warning"; // Drug Warnings
export { default as videoConsultationAvailability } from "./videoConsultationAvailability"; // Video Consultation Availability
export { default as vaccine } from "./vaccine"; // Vaccine
export { default as vaccineType } from "./vaccineType"; // Vaccine Type
export { default as vaccineBatch } from "./vaccineBatch"; // Vaccine Batch
export { default as videoConsultation } from "./videoConsultation"; // Video Consultation
export { default as visitTemplate } from "./visitTemplate"; // Video Consultation
export { default as voiceRecognition } from "./voiceRecognition"; // Voice Recognition
