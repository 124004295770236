import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

import ScrollToTop from "./components/helper/scrollToTop";
import Loading from "./components/loading/loading";

// Asset files
import { routes } from "./assets/files/routes";

// Admin console pages
const AdminPageWrapper = lazy(() => import("dg-admin/build/AdminPageWrapper"));
const Sidebar = lazy(() => import("dg-admin/build/Sidebar"));
const ProtectedRoute = lazy(() => import("dg-admin/build/ProtectedRoute"));

const UserPageWrapper = lazy(() =>
  import("./components/pageWrapper/component")
);
// Import Pages
const Home = lazy(() => import("./pages/home/page"));
const UserDashboard = lazy(() => import("./pages/userDashboard/page"));
const Appointments = lazy(() => import("./pages/appointments/page"));
const UiKit = lazy(() => import("./pages/uiKit"));
const Fullchat = lazy(() => import("./pages/fullchat/page"));
const NewTask = lazy(() => import("./pages/newtask/page"));
const EditTaskNonpatient = lazy(() =>
  import("./pages/editTaskNonpatient/page")
);
const NewPatient = lazy(() => import("./pages/newPatient/page"));
const Registers = lazy(() => import("./pages/registers/page"));
const CurrentReminders = lazy(() => import("./pages/currentReminders/page"));
const Tasks = lazy(() => import("./pages/task/page"));
const Appointmentsday = lazy(() => import("./pages/appointmentsday/page"));
const AppointmentsWeekView = lazy(() =>
  import("./pages/appointmentsWeekView/page")
);
const AppointmentsWaitingRoom = lazy(() =>
  import("./pages/appointmentsWaitingRoom/page")
);
const SearchResults = lazy(() => import("./pages/searchResults/page"));
const SearchPage = lazy(() => import("./pages/search/page"));
const MediForm = lazy(() => import("./pages/mediForm/page"));

// Patient
const PatientList = lazy(() => import("./pages/patient/list/page"));
const PatientDashboard = lazy(() => import("./pages/patient/dashboard/page"));
const PatientVisit = lazy(() => import("./pages/patientVisit/page"));
const PatientVisitLabOrder = lazy(() =>
  import("./pages/patientVisitLabOrder/page")
);
const Flowchart = lazy(() => import("./pages/flowchart/page"));
const PatientVisitSickGeneral = lazy(() =>
  import("./pages/patientVisitSickGeneral/page")
);
const PatientVisitVaccine = lazy(() =>
  import("./pages/patientVisitVaccine/page")
);
const PatientVisitReferral = lazy(() =>
  import("./pages/patientVisitReferral/page")
);
const EncounterCreate = lazy(() => import("./pages/encounter/create/page"));
const EncounterList = lazy(() => import("./pages/encounter/list/page"));

const MedicationRequestCreate = lazy(() =>
  import("./pages/medicationRequest/create/page")
);
const MedicationRequestList = lazy(() =>
  import("./pages/medicationRequest/list/page")
);

const ServiceRequest = lazy(() => import("./pages/serviceRequest/page"));
const CurrentPrescriptions = lazy(() =>
  import("./pages/currentPrescriptions/page")
);
const RepeatingPrescriptions = lazy(() =>
  import("./pages/repeatingPrescriptions/page")
);
const OutgoingCorrespondence = lazy(() =>
  import("./pages/outgoingCorrespondence/page")
);
const TableView = lazy(() => import("./pages/tableView/page"));
const OutstandingPayments = lazy(() =>
  import("./pages/outstandingPayments/page")
);
const OutstandingPaymentsPatients = lazy(() =>
  import("./pages/outstandingPaymentsPatients/page")
);
const ImmunizationCreate = lazy(() => import("./pages/immunization/page"));
const LicenceEyesightReport = lazy(() =>
  import("./pages/LicenceEyesightReport/page")
);
const PatientSearch = lazy(() => import("./pages/patientSearch/page"));
const NewResults = lazy(() => import("./pages/newResults/page"));
const Login = lazy(() => import("./pages/login/page"));
const PatientPreviousVisits = lazy(() =>
  import("./pages/patientPreviousVisits/page")
);
const PatientJabs = lazy(() => import("./pages/patientJabs/page"));
const CalendarSelection = lazy(() => import("./pages/calendarSelection/page"));
const ConfirmBooking = lazy(() => import("./pages/confirmBooking/page"));
const ThankYou = lazy(() => import("./pages/thankYou/page"));
const PatientDetails = lazy(() => import("./pages/patientDetails/page"));
const VCLink = lazy(() => import("./pages/vcLink/page"));

//--- Admin Pages ---

// Login Pages
const LoginPage = lazy(() => import("dg-admin/build/LoginPage"));
const PasswordResetPage = lazy(() =>
  import("dg-admin/build/PasswordResetPage")
);

// User Pages
const AdminUserListPage = lazy(() => import("dg-admin/build/UserList"));
const AdminUserCreatePage = lazy(() => import("dg-admin/build/CreateUserPage"));
const AdminUserEditPage = lazy(() => import("dg-admin/build/EditUserPage"));

// Clinic Pages
const AdminClinicListPage = lazy(() =>
  import("./pages/admin/clinic/list/page")
);
const AdminClinicEditPage = lazy(() =>
  import("./pages/admin/clinic/edit/page")
);
const AdminClinicCreatePage = lazy(() =>
  import("./pages/admin/clinic/create/page")
);

function Router() {
  const navigate = useNavigate();
  return (
    <Suspense fallback={<Loading />}>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route
          path="/11iblokauz41k49fw1ja26e7s39g5jp8dx5fuurnru7sgbmpct"
          exact
          element={
            <LoginPage
              createAccount={false}
              onSuccess={() => navigate("/patient")}
            />
          }
        />
        <Route
          path="/appointments"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Appointments}
              routes={routes}
            />
          }
        />
        <Route
          path="/userdashboard"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={UserDashboard}
              routes={routes}
            />
          }
        />
        <Route
          path="/uikit"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={UiKit}
              routes={routes}
            />
          }
        />
        <Route
          path="/full-chat"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Fullchat}
              routes={routes}
            />
          }
        />
        <Route
          path="/new-task"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={NewTask}
              routes={routes}
            />
          }
        />
        <Route
          path="/non-patient"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={EditTaskNonpatient}
              routes={routes}
            />
          }
        />
        <Route
          path="/new-patient"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={NewPatient}
              routes={routes}
            />
          }
        />
        <Route
          path="/registers"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Registers}
              routes={routes}
            />
          }
        />
        <Route
          path="/tasks"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Tasks}
              routes={routes}
            />
          }
        />
        <Route
          path="/current-reminders"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={CurrentReminders}
              routes={routes}
            />
          }
        />
        <Route
          path="/appointments-day"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Appointmentsday}
              routes={routes}
            />
          }
        />
        <Route
          path="/appointments-week-view"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={AppointmentsWeekView}
              routes={routes}
            />
          }
        />
        <Route
          path="/appointments-waiting-room"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={AppointmentsWaitingRoom}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientList}
              routes={routes}
            />
          }
        />

        <Route
          path="/patient/:patientId/summary"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientDashboard}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisit}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-lab-order"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitLabOrder}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-sick-general"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitSickGeneral}
              routes={routes}
            />
          }
        />
        <Route
          path="/search-results"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={SearchResults}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-vaccine"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitVaccine}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-referral"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitReferral}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/encounter/:encounterId/immunization/:immunizationId"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={ImmunizationCreate}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/encounter/"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={EncounterList}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/encounter/:encounterId"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={EncounterCreate}
              routes={routes}
            />
          }
        />
        <Route
          path="/mediform"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={MediForm}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/encounter/:encounterId/medicationrequest/:medicationRequestId"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={MedicationRequestCreate}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/encounter/:encounterId/servicerequest/:serviceRequestId"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={ServiceRequest}
              routes={routes}
            />
          }
        />
        <Route
          path="/current-prescriptions"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={CurrentPrescriptions}
              routes={routes}
            />
          }
        />
        <Route
          path="/search"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={SearchPage}
              routes={routes}
            />
          }
        />
        <Route
          path="/outstanding-payments"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={OutstandingPayments}
              routes={routes}
            />
          }
        />
        <Route
          path="/outstanding-payments-patients"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={OutstandingPaymentsPatients}
              routes={routes}
            />
          }
        />
        <Route
          path="/licence-eyesight-report"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={LicenceEyesightReport}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-search"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientSearch}
              routes={routes}
            />
          }
        />
        <Route
          path="/new-results"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={NewResults}
              routes={routes}
            />
          }
        />
        <Route
          path="/login"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Login}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-previous-visits"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientPreviousVisits}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/medicationrequest"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={MedicationRequestList}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/flowchart"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={Flowchart}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient/:patientId/jabs"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientJabs}
              routes={routes}
            />
          }
        />

        <Route
          path="/patient-visit"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisit}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-lab-order"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitLabOrder}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-sick-general"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitSickGeneral}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-vaccine"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitVaccine}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-visit-referral"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientVisitReferral}
              routes={routes}
            />
          }
        />
        <Route
          path="/outgoing-correspondence"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={OutgoingCorrespondence}
              routes={routes}
            />
          }
        />
        <Route
          path="/table-view"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={TableView}
              routes={routes}
            />
          }
        />
        <Route
          path="/calendar-selection"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={CalendarSelection}
              routes={routes}
            />
          }
        />
        <Route
          path="/patient-details"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={PatientDetails}
              routes={routes}
            />
          }
        />
        <Route
          path="/confirm-booking"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={ConfirmBooking}
              routes={routes}
            />
          }
        />
        <Route
          path="/thank-you"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={ThankYou}
              routes={routes}
            />
          }
        />

        <Route
          path="/vc-link"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={UserPageWrapper}
              wrappedComponent={VCLink}
              routes={routes}
            />
          }
        />

        <Route
          path="/admin"
          exact
          element={
            <LoginPage
              createAccount={false}
              onSuccess={() => navigate("/admin/user")}
            />
          }
        />
        <Route
          path="/verifypasswordreset"
          exact
          element={<PasswordResetPage />}
        />
        <Route
          path="/admin/user"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminUserListPage}
              routes={routes}
            />
          }
        />
        <Route
          path="/admin/user/create"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminUserCreatePage}
              routes={routes}
            />
          }
        />
        <Route
          path="/admin/user/edit/:id"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminUserEditPage}
              routes={routes}
            />
          }
        />
        <Route
          path="/admin/clinic"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminClinicListPage}
              routes={routes}
            />
          }
        />
        <Route
          path="/admin/clinic/create"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminClinicCreatePage}
              routes={routes}
            />
          }
        />
        <Route
          path="/admin/clinic/edit/:id"
          exact
          element={
            <DgLazyComponent
              Component={ProtectedRoute}
              component={AdminPageWrapper}
              wrappedComponent={AdminClinicEditPage}
              routes={routes}
            />
          }
        />
        <Route path="*" element={<HomeRedirect />} />
      </Routes>
    </Suspense>
  );
}

function HomeRedirect() {
  const navigate = useNavigate();
  useEffect(() => navigate("/"), []);
  return <div></div>;
}

export default Router;
