import {
  CREATE_DIAGNOSTIC_REPORT,
  UPDATE_DIAGNOSTIC_REPORT,
  DELETE_DIAGNOSTIC_REPORT,
  GET_DIAGNOSTIC_REPORT,
  GET_ALL_DIAGNOSTIC_REPORT,
  SET_CURRENT_DIAGNOSTIC_REPORT,
} from "../actions/types";

const initialState = {
  currentDiagnosticReport: {},
  diagnosticReports: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_DIAGNOSTIC_REPORT:
    case CREATE_DIAGNOSTIC_REPORT:
    case UPDATE_DIAGNOSTIC_REPORT:
    case GET_DIAGNOSTIC_REPORT:
      return {
        ...state,
        currentDiagnosticReport: action.payload,
      };
    case DELETE_DIAGNOSTIC_REPORT:
      return {
        ...state,
        currentDiagnosticReport: {},
      };
    case GET_ALL_DIAGNOSTIC_REPORT:
      return {
        ...state,
        diagnosticReports: action.payload,
      };
    default:
      return state;
  }
}
