import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

//import ProtectedRoute from "./components/protectedRoute.component";
import ScrollToTop from "./components/helper/scrollToTop";
import Loading from "./components/loading/loading";

// Asset files
import { routes as adminRoutes } from "./assets/files/adminRoutes";

// Import from packages
const AdminPageWrapper = lazy(() => import("dg-admin/build/AdminPageWrapper"));
const Sidebar = lazy(() => import("dg-admin/build/Sidebar"));
const ProtectedRoute = lazy(() => import("dg-admin/build/ProtectedRoute"));

// Import Page Components
// Main Pages
const Home =lazy(()=>import( "./components/home/home.component"));
const Cleaning =lazy(()=>import( "./components/cleaning/cleaning.component"));
const RestaurantKitchen =lazy(()=>import( "./components/restaurantKitchen/restaurant.component"));
const DuctCleaning =lazy(()=>import( "./components/ductCleaning/ductCleaning.component"));
const ExtractorFan =lazy(()=>import( "./components/extractorFan/extractorFan.component"));
const Installation =lazy(()=>import( "./components/installation_design/installationDesign.component"));
const Manufacturing =lazy(()=>import( "./components/manufacturing/manufacturing.component"));
const FanBreakdown =lazy(()=>import( "./components/fan_breakdown/fanBreakdown.component"));
const RecentProjects =lazy(()=>import( "./components/recent_projects/recentProjects.component"));
const SafeAir =lazy(()=>import( "./components/safe_air/safeAir.component"));
const Contact =lazy(()=>import( "./components/contact/contact.component"));
//const CookiePolicy = lazy(() => import("./pages/cookiePolicy"));

// Admin
const LoginPage = lazy(() => import("dg-admin/build/LoginPage"));
const PasswordResetPage = lazy(() =>
    import("dg-admin/build/PasswordResetPage")
);
const AdminUserListPage = lazy(() => import("dg-admin/build/UserList"));
const AdminUserCreatePage = lazy(() => import("dg-admin/build/CreateUserPage"));
const AdminUserEditPage = lazy(() => import("dg-admin/build/EditUserPage"));
const AdminContactList = lazy(() => import("./pages/admin/contact/list"));
const AdminContactView = lazy(() => import("./pages/admin/contact/edit"));

function Router() {
    const navigate = useNavigate();

    return (
        <Suspense fallback={<Loading />}>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/industrial-commercial-cleaning" exact element={<Cleaning />} />
                <Route path="/cleaning" element={<Navigate to="/industrial-commercial-cleaning" />} />
                <Route path="/industrial-commercial-cleaning/restaurant-kitchen" exact element={<RestaurantKitchen />} />
                <Route path="/industrial-commercial-cleaning/duct-cleaning" exact element={<DuctCleaning />} />
                <Route path="/industrial-commercial-cleaning/extractor-fan" exact element={<ExtractorFan />} />
                <Route path="/installation" exact element={<Installation />} />
                <Route
                    path="/manufacturing"
                    exact
                    element={<Manufacturing />}
                />
                <Route
                    path="/recent-projects"
                    exact
                    element={<RecentProjects />}
                />
                <Route path="/enviro-safe-air" exact element={<SafeAir />} />
                <Route path="/fan-breakdown" exact element={<FanBreakdown />} />
                <Route path="/contact" exact element={<Contact />} />
                <Route
                    path="/admin"
                    exact
                    element={
                        <LoginPage
                            createAccount={false}
                            onSuccess={()=>navigate("/admin/contact")}
                        />
                    }
                />
                <Route
                    path="/verifypasswordreset"
                    exact
                    element={<PasswordResetPage />}
                />
                <Route
                    path="/admin/user"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminPageWrapper}
                            wrappedComponent={AdminUserListPage}
                            routes={adminRoutes}
                        />
                    }
                />
                <Route
                    path="/admin/user/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminPageWrapper}
                            wrappedComponent={AdminUserCreatePage}
                            routes={adminRoutes}
                        />
                    }
                />
                <Route
                    path="/admin/user/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminPageWrapper}
                            wrappedComponent={AdminUserEditPage}
                            routes={adminRoutes}
                        />
                    }
                />

                <Route
                    path="/admin/contact"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminPageWrapper}
                            wrappedComponent={AdminContactList}
                            routes={adminRoutes}
                        />
                    }
                />
                <Route
                    path="/admin/contact/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminPageWrapper}
                            wrappedComponent={AdminContactView}
                            routes={adminRoutes}
                        />
                    }
                />
                <Route path="*" element={<HomeRedirect />} />
            </Routes>
        </Suspense>
    );
}

function HomeRedirect() {
    const navigate = useNavigate();
    useEffect(() => navigate("/"), []);
    return <div></div>;
}

export default Router;
