import {
    CREATE_DOCUMENT_REFERENCE,
    UPDATE_DOCUMENT_REFERENCE,
    DELETE_DOCUMENT_REFERENCE,
    GET_DOCUMENT_REFERENCE,
    GET_ALL_DOCUMENT_REFERENCE,
    SET_CURRENT_DOCUMENT_REFERENCE,
} from "../actions/types";

const initialState = {
    currentDocumentReference: {},
    documentReferences: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_DOCUMENT_REFERENCE:
        case CREATE_DOCUMENT_REFERENCE:
        case UPDATE_DOCUMENT_REFERENCE:
        case GET_DOCUMENT_REFERENCE:
            return {
                ...state,
                currentDocumentReference: action.payload,
            };
        case DELETE_DOCUMENT_REFERENCE:
            return {
                ...state,
                currentDocumentReference: {},
            };
        case GET_ALL_DOCUMENT_REFERENCE:
            return {
                ...state,
                documentReferences: action.payload,
            };
        default:
            return state;
    }
}
