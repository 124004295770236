import {
  CREATE_IMMUNIZATION_SITE,
  UPDATE_IMMUNIZATION_SITE,
  DELETE_IMMUNIZATION_SITE,
  GET_IMMUNIZATION_SITE,
  GET_ALL_IMMUNIZATION_SITE,
  SET_CURRENT_IMMUNIZATION_SITE,
} from "../actions/types";

const initialState = {
  currentImmunizationSite: {},
  immunizationSites: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_IMMUNIZATION_SITE:
    case CREATE_IMMUNIZATION_SITE:
    case UPDATE_IMMUNIZATION_SITE:
    case GET_IMMUNIZATION_SITE:
      return {
        ...state,
        currentImmunizationSite: action.payload,
      };
    case DELETE_IMMUNIZATION_SITE:
      return {
        ...state,
        currentImmunizationSite: {},
      };
    case GET_ALL_IMMUNIZATION_SITE:
      return {
        ...state,
        immunizationSites: action.payload,
      };
    default:
      return state;
  }
}
