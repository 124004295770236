import {
    CREATE_DRUG_WARNING,
    UPDATE_DRUG_WARNING,
    DELETE_DRUG_WARNING,
    GET_DRUG_WARNING,
    GET_ALL_DRUG_WARNING,
    SET_CURRENT_DRUG_WARNING,
    GET_PATIENT_DRUG_WARNINGS,
} from "../../actions/types";

const initialState = {
    currentWarning: {},
    warnings: [],
    patientWarnings: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_DRUG_WARNING:
        case CREATE_DRUG_WARNING:
        case UPDATE_DRUG_WARNING:
        case GET_DRUG_WARNING:
            return {
                ...state,
                currentWarning: action.payload,
            };
        case DELETE_DRUG_WARNING:
            return {
                ...state,
                currentWarning: {},
            };
        case GET_ALL_DRUG_WARNING:
            return {
                ...state,
                warnings: action.payload,
            };
        case GET_PATIENT_DRUG_WARNINGS:
            return {
                ...state,
                patientWarnings: action.payload,
            };
        default:
            return state;
    }
}
