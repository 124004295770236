import {
    APPEND_MODAL_DATA,
  SET_IS_MODAL_OPEN,
  SET_MODAL_DATA,
  SET_MODAL_NAME,
} from "../actions/types";

const initialState = {
  isOpen: false,
  name: "",
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      };
    case SET_MODAL_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case APPEND_MODAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
