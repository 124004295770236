import {
  CREATE_VIDEO_CONSULTATION,
  UPDATE_VIDEO_CONSULTATION,
  DELETE_VIDEO_CONSULTATION,
  GET_VIDEO_CONSULTATION,
  GET_ALL_VIDEO_CONSULTATION,
  SET_CURRENT_VIDEO_CONSULTATION,
} from "../actions/types";

const initialState = {
  currentVideoConsultation: {},
  videoCunsultations: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VIDEO_CONSULTATION:
    case CREATE_VIDEO_CONSULTATION:
    case UPDATE_VIDEO_CONSULTATION:
    case GET_VIDEO_CONSULTATION:
      return {
        ...state,
        currentVideoConsultation: action.payload,
      };
    case DELETE_VIDEO_CONSULTATION:
      return {
        ...state,
        currentVideoConsultation: {},
      };
    case GET_ALL_VIDEO_CONSULTATION:
      return {
        ...state,
        videoCunsultations: action.payload,
      };
    default:
      return state;
  }
}
