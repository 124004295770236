import { 
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_CREATE_FAILED,
    SET_IS_LOADING,
    SET_PAYMENT_OPTION
 } from '../actions/types';

const initialState = {
    isLoading: false,
    paymentOption: '',
    payment: {},
    ok: true,
    message: '',
    errors: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case PAYMENT_CREATE_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                payment: action.payload.payment,
                message: action.payload.message
            }
        case PAYMENT_CREATE_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_PAYMENT_OPTION:
            return {
                ...state,
                paymentOption: action.payload
            }
        default:
            return state;
    }
}