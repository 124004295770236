import {
    CREATE_STAFF,
    UPDATE_STAFF,
    DELETE_STAFF,
    GET_STAFF,
    GET_ALL_STAFF,
    SET_CURRENT_STAFF,
} from "../actions/types";

const initialState = {
    currentStaff: {},
    staff: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_STAFF:
        case CREATE_STAFF:
        case UPDATE_STAFF:
        case GET_STAFF:
            return {
                ...state,
                currentStaff: action.payload,
            };
        case DELETE_STAFF:
            return {
                ...state,
                currentStaff: {},
            };
        case GET_ALL_STAFF:
            return {
                ...state,
                staff: action.payload,
            };
        default:
            return state;
    }
}
