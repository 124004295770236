// Allery Intolerances
export const CREATE_ALLERGY_INTOLERANCE = "CREATE_ALLERGY_INTOLERANCE";
export const UPDATE_ALLERGY_INTOLERANCE = "UPDATE_ALLERGY_INTOLERANCE";
export const DUPLICATE_ALLERGY_INTOLERANCE = "DUPLICATE_ALLERGY_INTOLERANCE";
export const DELETE_ALLERGY_INTOLERANCE = "DELETE_ALLERGY_INTOLERANCE";
export const GET_ALLERGY_INTOLERANCE = "GET_ALLERGY_INTOLERANCE";
export const GET_ALL_ALLERGY_INTOLERANCE = "GET_ALL_ALLERGY_INTOLERANCE";
export const SET_CURRENT_ALLERGY_INTOLERANCE =
  "SET_CURRENT_ALLERGY_INTOLERANCE";

// Appoinment
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const DUPLICATE_APPOINTMENT = "DUPLICATE_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_ALL_GROUPED_APPOINTMENT = "GET_ALL_GROUPED_APPOINTMENT";
export const GET_ALL_APPOINTMENT = "GET_ALL_APPOINTMENT";
export const SET_CURRENT_APPOINTMENT = "SET_CURRENT_APPOINTMENT";

// Audit Event
export const CREATE_AUDIT_EVENT = "CREATE_AUDIT_EVENT";
export const UPDATE_AUDIT_EVENT = "UPDATE_AUDIT_EVENT";
export const DUPLICATE_AUDIT_EVENT = "DUPLICATE_AUDIT_EVENT";
export const DELETE_AUDIT_EVENT = "DELETE_AUDIT_EVENT";
export const GET_AUDIT_EVENT = "GET_AUDIT_EVENT";
export const GET_ALL_AUDIT_EVENT = "GET_ALL_AUDIT_EVENT";
export const SET_CURRENT_AUDIT_EVENT = "SET_CURRENT_AUDIT_EVENT";

// Clinics
export const CREATE_CLINIC = "CREATE_CLINIC";
export const UPDATE_CLINIC = "UPDATE_CLINIC";
export const DUPLICATE_CLINIC = "DUPLICATE_CLINIC";
export const DELETE_CLINIC = "DELETE_CLINIC";
export const GET_CLINIC = "GET_CLINIC";
export const GET_ALL_CLINIC = "GET_ALL_CLINIC";
export const SET_CURRENT_CLINIC = "SET_CURRENT_CLINIC";

// Diagnostic Report
export const CREATE_DIAGNOSTIC_REPORT = "CREATE_DIAGNOSTIC_REPORT";
export const UPDATE_DIAGNOSTIC_REPORT = "UPDATE_DIAGNOSTIC_REPORT";
export const DUPLICATE_DIAGNOSTIC_REPORT = "DUPLICATE_DIAGNOSTIC_REPORT";
export const DELETE_DIAGNOSTIC_REPORT = "DELETE_DIAGNOSTIC_REPORT";
export const GET_DIAGNOSTIC_REPORT = "GET_DIAGNOSTIC_REPORT";
export const GET_ALL_DIAGNOSTIC_REPORT = "GET_ALL_DIAGNOSTIC_REPORT";
export const SET_CURRENT_DIAGNOSTIC_REPORT = "SET_CURRENT_DIAGNOSTIC_REPORT";

// Document References
export const CREATE_DOCUMENT_REFERENCE = "CREATE_DOCUMENT_REFERENCE";
export const UPDATE_DOCUMENT_REFERENCE = "UPDATE_DOCUMENT_REFERENCE";
export const DUPLICATE_DOCUMENT_REFERENCE = "DUPLICATE_DOCUMENT_REFERENCE";
export const DELETE_DOCUMENT_REFERENCE = "DELETE_DOCUMENT_REFERENCE";
export const GET_DOCUMENT_REFERENCE = "GET_DOCUMENT_REFERENCE";
export const GET_ALL_DOCUMENT_REFERENCE = "GET_ALL_DOCUMENT_REFERENCE";
export const SET_CURRENT_DOCUMENT_REFERENCE = "SET_CURRENT_DOCUMENT_REFERENCE";

// Drug Product
export const CREATE_DRUG_PRODUCT = "CREATE_DRUG_PRODUCT";
export const UPDATE_DRUG_PRODUCT = "UPDATE_DRUG_PRODUCT";
export const DUPLICATE_DRUG_PRODUCT = "DUPLICATE_DRUG_PRODUCT";
export const DELETE_DRUG_PRODUCT = "DELETE_DRUG_PRODUCT";
export const GET_DRUG_PRODUCT = "GET_DRUG_PRODUCT";
export const GET_EXPANDED_DRUG_PRODUCT = "GET_DRUG_PRODUCT";
export const GET_ALL_DRUG_PRODUCT = "GET_ALL_DRUG_PRODUCT";
export const SET_CURRENT_DRUG_PRODUCT = "SET_CURRENT_DRUG_PRODUCT";

// Drug Product Warnings
export const CREATE_DRUG_WARNING = "CREATE_DRUG_WARNING";
export const UPDATE_DRUG_WARNING = "UPDATE_DRUG_WARNING";
export const DUPLICATE_DRUG_WARNING = "DUPLICATE_DRUG_WARNING";
export const DELETE_DRUG_WARNING = "DELETE_DRUG_WARNING";
export const GET_DRUG_WARNING = "GET_DRUG_WARNING";
export const GET_ALL_DRUG_WARNING = "GET_ALL_DRUG_WARNING";
export const SET_CURRENT_DRUG_WARNING = "SET_CURRENT_DRUG_WARNING";
export const GET_PATIENT_DRUG_WARNINGS = "GET_PATIENT_DRUG_WARNING";

// Drug Product Interactions
export const CREATE_DRUG_INTERACTION = "CREATE_DRUG_INTERACTION";
export const UPDATE_DRUG_INTERACTION = "UPDATE_DRUG_INTERACTION";
export const DUPLICATE_DRUG_INTERACTION = "DUPLICATE_DRUG_INTERACTION";
export const DELETE_DRUG_INTERACTION = "DELETE_DRUG_INTERACTION";
export const GET_DRUG_INTERACTION = "GET_DRUG_INTERACTION";
export const GET_ALL_DRUG_INTERACTION = "GET_ALL_DRUG_INTERACTION";
export const SET_CURRENT_DRUG_INTERACTION = "SET_CURRENT_DRUG_INTERACTION";
export const GET_PATIENT_DRUG_INTERACTIONS = "GET_PATIENT_DRUG_INTERACTION";

// Encounters
export const CREATE_ENCOUNTER = "CREATE_ENCOUNTER";
export const UPDATE_ENCOUNTER = "UPDATE_ENCOUNTER";
export const DUPLICATE_ENCOUNTER = "DUPLICATE_ENCOUNTER";
export const DELETE_ENCOUNTER = "DELETE_ENCOUNTER";
export const GET_ENCOUNTER = "GET_ENCOUNTER";
export const GET_ENCOUNTER_ENTRIES = "GET_ENCOUNTER_ENTRIES";
export const GET_PATIENT_ENCOUNTER_DATES = "GET_PATIENT_ENCOUNTER_DATES";
export const GET_ALL_ENCOUNTER = "GET_ALL_ENCOUNTER";
export const SET_CURRENT_ENCOUNTER = "SET_CURRENT_ENCOUNTER";
export const SET_IN_PROGRESS_ENCOUNTER = "SET_IN_PROGRESS_ENCOUNTER";

// Flowchart
export const CREATE_FLOWCHART = "CREATE_FLOWCHART";
export const UPDATE_FLOWCHART = "UPDATE_FLOWCHART";
export const DUPLICATE_FLOWCHART = "DUPLICATE_FLOWCHART";
export const DELETE_FLOWCHART = "DELETE_FLOWCHART";
export const GET_FLOWCHART = "GET_FLOWCHART";
export const GET_ALL_FLOWCHART = "GET_ALL_FLOWCHART";
export const SET_CURRENT_FLOWCHART = "SET_CURRENT_FLOWCHART";

// Immunization
export const CREATE_IMMUNIZATION = "CREATE_IMMUNIZATION";
export const UPDATE_IMMUNIZATION = "UPDATE_IMMUNIZATION";
export const DUPLICATE_IMMUNIZATION = "DUPLICATE_IMMUNIZATION";
export const DELETE_IMMUNIZATION = "DELETE_IMMUNIZATION";
export const GET_IMMUNIZATION = "GET_IMMUNIZATION";
export const GET_ALL_IMMUNIZATION = "GET_ALL_IMMUNIZATION";
export const SET_CURRENT_IMMUNIZATION = "SET_CURRENT_IMMUNIZATION";

// Immunization site
export const CREATE_IMMUNIZATION_SITE = "CREATE_IMMUNIZATION_SITE";
export const UPDATE_IMMUNIZATION_SITE = "UPDATE_IMMUNIZATION_SITE";
export const DUPLICATE_IMMUNIZATION_SITE = "DUPLICATE_IMMUNIZATION_SITE";
export const DELETE_IMMUNIZATION_SITE = "DELETE_IMMUNIZATION_SITE";
export const GET_IMMUNIZATION_SITE = "GET_IMMUNIZATION_SITE";
export const GET_ALL_IMMUNIZATION_SITE = "GET_ALL_IMMUNIZATION_SITE";
export const SET_CURRENT_IMMUNIZATION_SITE = "SET_CURRENT_IMMUNIZATION_SITE";

// Immunization route
export const CREATE_IMMUNIZATION_ROUTE = "CREATE_IMMUNIZATION_ROUTE";
export const UPDATE_IMMUNIZATION_ROUTE = "UPDATE_IMMUNIZATION_ROUTE";
export const DUPLICATE_IMMUNIZATION_ROUTE = "DUPLICATE_IMMUNIZATION_ROUTE";
export const DELETE_IMMUNIZATION_ROUTE = "DELETE_IMMUNIZATION_ROUTE";
export const GET_IMMUNIZATION_ROUTE = "GET_IMMUNIZATION_ROUTE";
export const GET_ALL_IMMUNIZATION_ROUTE = "GET_ALL_IMMUNIZATION_ROUTE";
export const SET_CURRENT_IMMUNIZATION_ROUTE = "SET_CURRENT_IMMUNIZATION_ROUTE";

// Locations
export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DUPLICATE_LOCATION = "DUPLICATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const GET_LOCATION = "GET_LOCATION";
export const GET_ALL_LOCATION = "GET_ALL_LOCATION";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";

// Lookups
export const CREATE_LOOKUP = "CREATE_LOOKUP";
export const UPDATE_LOOKUP = "UPDATE_LOOKUP";
export const DUPLICATE_LOOKUP = "DUPLICATE_LOOKUP";
export const DELETE_LOOKUP = "DELETE_LOOKUP";
export const GET_LOOKUP = "GET_LOOKUP";
export const GET_ALL_LOOKUP = "GET_ALL_LOOKUP";
export const SET_CURRENT_LOOKUP = "SET_CURRENT_LOOKUP";

// MEDICATION
export const CREATE_MEDICATION = "CREATE_MEDICATION";
export const UPDATE_MEDICATION = "UPDATE_MEDICATION";
export const DUPLICATE_MEDICATION = "DUPLICATE_MEDICATION";
export const DELETE_MEDICATION = "DELETE_MEDICATION";
export const GET_MEDICATION = "GET_MEDICATION";
export const GET_ALL_MEDICATION = "GET_ALL_MEDICATION";
export const SET_CURRENT_MEDICATION = "SET_CURRENT_MEDICATION";

// Medication batch
export const CREATE_MEDICATION_BATCH = "CREATE_MEDICATION_BATCH";
export const UPDATE_MEDICATION_BATCH = "UPDATE_MEDICATION_BATCH";
export const DUPLICATE_MEDICATION_BATCH = "DUPLICATE_MEDICATION_BATCH";
export const DELETE_MEDICATION_BATCH = "DELETE_MEDICATION_BATCH";
export const GET_MEDICATION_BATCH = "GET_MEDICATION_BATCH";
export const GET_ALL_MEDICATION_BATCH = "GET_ALL_MEDICATION_BATCH";
export const SET_CURRENT_MEDICATION_BATCH = "SET_CURRENT_MEDICATION_BATCH";
export const RESET_MEDICATION_BATCH = "RESET_MEDICATION_BATCH";


// Medication Requests
export const CREATE_MEDICATION_REQUEST = "CREATE_MEDICATION_REQUEST";
export const UPDATE_MEDICATION_REQUEST = "UPDATE_MEDICATION_REQUEST";
export const DUPLICATE_MEDICATION_REQUEST = "DUPLICATE_MEDICATION_REQUEST";
export const DELETE_MEDICATION_REQUEST = "DELETE_MEDICATION_REQUEST";
export const GET_MEDICATION_REQUEST = "GET_MEDICATION_REQUEST";
export const GET_ALL_MEDICATION_REQUEST = "GET_ALL_MEDICATION_REQUEST";
export const SET_CURRENT_MEDICATION_REQUEST = "SET_CURRENT_MEDICATION_REQUEST";
export const GET_DRUG_LAST_MEDICATION_REQUEST =
  "GET_DRUG_LAST_MEDICATION_REQUEST";

// Observations
export const CREATE_OBSERVATION = "CREATE_OBSERVATION";
export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION";
export const DUPLICATE_OBSERVATION = "DUPLICATE_OBSERVATION";
export const DELETE_OBSERVATION = "DELETE_OBSERVATION";
export const GET_OBSERVATION = "GET_OBSERVATION";
export const GET_ALL_OBSERVATION = "GET_ALL_OBSERVATION";
export const SET_CURRENT_OBSERVATION = "SET_CURRENT_OBSERVATION";

// Flowchart
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const DUPLICATE_ORGANIZATION = "DUPLICATE_ORGANIZATION";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_ALL_ORGANIZATION = "GET_ALL_ORGANIZATION";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";

// Patients
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DUPLICATE_PATIENT = "DUPLICATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const GET_PATIENT = "GET_PATIENT";
export const GET_PATIENT_MEDICAL_HISTORY = "GET_PATIENT_MEDICAL_HISTORY";
export const GET_ALL_PATIENT = "GET_ALL_PATIENT";
export const SET_CURRENT_PATIENT = "SET_CURRENT_PATIENT";

// Practitioners
export const CREATE_PRACTITIONER = "CREATE_PRACTITIONER";
export const UPDATE_PRACTITIONER = "UPDATE_PRACTITIONER";
export const DUPLICATE_PRACTITIONER = "DUPLICATE_PRACTITIONER";
export const DELETE_PRACTITIONER = "DELETE_PRACTITIONER";
export const GET_PRACTITIONER = "GET_PRACTITIONER";
export const GET_ALL_PRACTITIONER = "GET_ALL_PRACTITIONER";
export const SET_CURRENT_PRACTITIONER = "SET_CURRENT_PRACTITIONER";
export const SET_LOGGED_IN_PRACTITIONER = "SET_LOGGED_IN_PRACTITIONER";

// Practitioner Roles
export const CREATE_PRACTITIONER_ROLE = "CREATE_PRACTITIONER_ROLE";
export const UPDATE_PRACTITIONER_ROLE = "UPDATE_PRACTITIONER_ROLE";
export const DUPLICATE_PRACTITIONER_ROLE = "DUPLICATE_PRACTITIONER_ROLE";
export const DELETE_PRACTITIONER_ROLE = "DELETE_PRACTITIONER_ROLE";
export const GET_PRACTITIONER_ROLE = "GET_PRACTITIONER_ROLE";
export const GET_ALL_GROUPED_PRACTITIONER_ROLE =
  "GET_ALL_GROUPED_PRACTITIONER_ROLE";
export const GET_ALL_PRACTITIONER_ROLE = "GET_ALL_PRACTITIONER_ROLE";
export const SET_CURRENT_PRACTITIONER_ROLE = "SET_CURRENT_PRACTITIONER_ROLE";

// Prescriptions
export const CREATE_PRESCRIPTION = "CREATE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const DUPLICATE_PRESCRIPTION = "DUPLICATE_PRESCRIPTION";
export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const GET_PRESCRIPTION = "GET_PRESCRIPTION";
export const GET_ALL_PRESCRIPTION = "GET_ALL_PRESCRIPTION";
export const SET_CURRENT_PRESCRIPTION = "SET_CURRENT_PRESCRIPTION";

// Service Requests
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const DUPLICATE_SERVICE_REQUEST = "DUPLICATE_SERVICE_REQUEST";
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_ALL_SERVICE_REQUEST = "GET_ALL_SERVICE_REQUEST";
export const SET_CURRENT_SERVICE_REQUEST = "SET_CURRENT_SERVICE_REQUEST";

// Staff
export const CREATE_STAFF = "CREATE_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const DUPLICATE_STAFF = "DUPLICATE_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";
export const GET_STAFF = "GET_STAFF";
export const GET_ALL_STAFF = "GET_ALL_STAFF";
export const SET_CURRENT_STAFF = "SET_CURRENT_STAFF";

// Modals
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_MODAL_NAME = "SET_MODAL_NAME";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const APPEND_MODAL_DATA = "APPEND_MODAL_DATA";

// Utilities
export const SET_IS_MOBILE_MENU_OPEN = "SET_IS_MOBILE_MENU_OPEN";
export const SET_IS_LOADING = "SET_IS_LOADING";

// Search
export const GET_ALL_SEARCH = "GET_ALL_SEARCH";

// Vaccine
export const CREATE_VACCINE = "CREATE_VACCINE";
export const UPDATE_VACCINE = "UPDATE_VACCINE";
export const DUPLICATE_VACCINE = "DUPLICATE_VACCINE";
export const DELETE_VACCINE = "DELETE_VACCINE";
export const GET_VACCINE = "GET_VACCINE";
export const GET_ALL_VACCINE = "GET_ALL_VACCINE";
export const SET_CURRENT_VACCINE = "SET_CURRENT_VACCINE";

// Vaccine batch
export const CREATE_VACCINE_BATCH = "CREATE_VACCINE_BATCH";
export const UPDATE_VACCINE_BATCH = "UPDATE_VACCINE_BATCH";
export const DUPLICATE_VACCINE_BATCH = "DUPLICATE_VACCINE_BATCH";
export const DELETE_VACCINE_BATCH = "DELETE_VACCINE_BATCH";
export const GET_VACCINE_BATCH = "GET_VACCINE_BATCH";
export const GET_ALL_VACCINE_BATCH = "GET_ALL_VACCINE_BATCH";
export const SET_CURRENT_VACCINE_BATCH = "SET_CURRENT_VACCINE_BATCH";
export const RESET_VACCINE_BATCH = "RESET_VACCINE_BATCH";

// Vaccine Type
export const CREATE_VACCINE_TYPE = "CREATE_VACCINE_TYPE";
export const UPDATE_VACCINE_TYPE = "UPDATE_VACCINE_TYPE";
export const DUPLICATE_VACCINE_TYPE = "DUPLICATE_VACCINE_TYPE";
export const DELETE_VACCINE_TYPE = "DELETE_VACCINE_TYPE";
export const GET_VACCINE_TYPE = "GET_VACCINE_TYPE";
export const GET_ALL_VACCINE_TYPE = "GET_ALL_VACCINE_TYPE";
export const SET_CURRENT_VACCINE_TYPE = "SET_CURRENT_VACCINE_TYPE";

// Vide Consultation Availability
export const CREATE_VIDEO_CONSULTATION_AVAILABILITY =
  "CREATE_VIDEO_CONSULTATION_AVAILABILITY";
export const UPDATE_VIDEO_CONSULTATION_AVAILABILITY =
  "UPDATE_VIDEO_CONSULTATION_AVAILABILITY";
export const DUPLICATE_VIDEO_CONSULTATION_AVAILABILITY =
  "DUPLICATE_VIDEO_CONSULTATION_AVAILABILITY";
export const DELETE_VIDEO_CONSULTATION_AVAILABILITY =
  "DELETE_VIDEO_CONSULTATION_AVAILABILITY";
export const GET_VIDEO_CONSULTATION_AVAILABILITY =
  "GET_VIDEO_CONSULTATION_AVAILABILITY";
export const GET_ALL_GROUPED_VIDEO_CONSULTATION_AVAILABILITY =
  "GET_ALL_GROUPED_VIDEO_CONSULTATION_AVAILABILITY";
export const GET_ALL_VIDEO_CONSULTATION_AVAILABILITY =
  "GET_ALL_VIDEO_CONSULTATION_AVAILABILITY";
export const GET_NEAREST_VIDEO_CONSULTATION_AVAILABILITY =
  "GET_NEAREST_VIDEO_CONSULTATION_AVAILABILITY";
export const SET_CURRENT_VIDEO_CONSULTATION_AVAILABILITY =
  "SET_CURRENT_VIDEO_CONSULTATION_AVAILABILITY";

// Vide Consultation
export const CREATE_VIDEO_CONSULTATION = "CREATE_VIDEO_CONSULTATION";
export const UPDATE_VIDEO_CONSULTATION = "UPDATE_VIDEO_CONSULTATION";
export const DUPLICATE_VIDEO_CONSULTATION = "DUPLICATE_VIDEO_CONSULTATION";
export const DELETE_VIDEO_CONSULTATION = "DELETE_VIDEO_CONSULTATION";
export const GET_VIDEO_CONSULTATION = "GET_VIDEO_CONSULTATION";
export const GET_ALL_GROUPED_VIDEO_CONSULTATION =
  "GET_ALL_GROUPED_VIDEO_CONSULTATION";
export const GET_ALL_VIDEO_CONSULTATION = "GET_ALL_VIDEO_CONSULTATION";
export const SET_CURRENT_VIDEO_CONSULTATION = "SET_CURRENT_VIDEO_CONSULTATION";

// Visit Template
export const CREATE_VISIT_TEMPLATE = "CREATE_VISIT_TEMPLATE";
export const UPDATE_VISIT_TEMPLATE = "UPDATE_VISIT_TEMPLATE";
export const DUPLICATE_VISIT_TEMPLATE = "DUPLICATE_VISIT_TEMPLATE";
export const DELETE_VISIT_TEMPLATE = "DELETE_VISIT_TEMPLATE";
export const GET_VISIT_TEMPLATE = "GET_VISIT_TEMPLATE";
export const GET_ALL_VISIT_TEMPLATE = "GET_ALL_VISIT_TEMPLATE";
export const SET_CURRENT_VISIT_TEMPLATE = "SET_CURRENT_VISIT_TEMPLATE";

// Voice recognition
export const CREATE_VOICE_RECOGNITION = "CREATE_VOICE_RECOGNITION";
export const UPDATE_VOICE_RECOGNITION = "UPDATE_VOICE_RECOGNITION";
export const DUPLICATE_VOICE_RECOGNITION = "DUPLICATE_VOICE_RECOGNITION";
export const DELETE_VOICE_RECOGNITION = "DELETE_VOICE_RECOGNITION";
export const GET_VOICE_RECOGNITION = "GET_VOICE_RECOGNITION";
export const GET_ALL_VOICE_RECOGNITION = "GET_ALL_VOICE_RECOGNITION";
export const SET_CURRENT_VOICE_RECOGNITION = "SET_CURRENT_VOICE_RECOGNITION";
export const SET_CURRENT_SEARCH = "SET_CURRENT_SEARCH";
