import React, { lazy, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-modal";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";
// Styles
import "./style.scss";

// Actions
import setIsOpen from "../../actions/modal/setIsOpen";
import setName from "../../actions/modal/setName";
import setData from "../../actions/modal/setData";

// Components
const FreeTextNote = lazy(() => import("../textNote/component"));
const AllergyIntolerance = lazy(() => import("../miniPopups/Allergy/component"));
const BloodPressure = lazy(() => import("../miniPopups/bloodPressure/component"));
const ChatHoverLg = lazy(() => import("../chatHoverLg/component"));
const DrugContraIndications = lazy(() => import("../drug/contraIndications/component"));
const DrugIndications = lazy(() => import("../drug/indications/component"));
const DrugSideEffects = lazy(() => import("../drug/sideEffects/component"));
const DrugRecommendedDose = lazy(() => import("../drug/recommendedDose/component"));
const PrescriptionPopup = lazy(() => import("../prescriptionPopup/component"));
const BodyTemperature = lazy(() => import("../miniPopups/bodyTemperature/component"));
const HeadCircumference = lazy(() => import("../miniPopups/headCircumference/component"));
const Height = lazy(() => import("../miniPopups/height/component"));
const PeakFlow = lazy(() => import("../miniPopups/peakFlow/component"));
const PulseRate = lazy(() => import("../pulseRate/component"));
const WaistCircumference = lazy(() => import("../miniPopups/waistCircumference/component"));
const Weight = lazy(() => import("../miniPopups/weight/component"));
const AuthorisePopup = lazy(() => import("../authorisePopup/component"));
const FinishPopup = lazy(() => import("../finishPopup/component"));
const ReviewPopup = lazy(() => import("../reviewPopup/component"));
const History = lazy(() => import("../history/component"));
const VisitTemplate = lazy(() => import("../visitTemplate/component"));
const SearchEntryType = lazy(() => import("../searchEntryType/component"));






function ComponentFn(props) {
    const { search } = useLocation();

    useEffect(() => {
        Modal.setAppElement("body");
    }, []);

    useEffect(() => {
        // Check if a modal should be open by default
        if (
            search.includes("modal=open") &&
            decodeURIComponent(search).includes("modalName=")
        ) {
            // Set the modal which should be open
            props.setName(
                decodeURIComponent(search)
                    .split("modalName=")
                    .pop()
                    .split("&")[0]
            );
            // Open the modal
            props.setIsOpen(true);
        }
    }, []);

    // Determine which modal to show
    const Content = useMemo(() => {
        switch (props.name) {
            case "allergyIntolerance":
                return <DgLazyComponent Component={AllergyIntolerance} />;
            case "bloodPressure":
                return <DgLazyComponent Component={BloodPressure} />;
            case "chat":
                return <DgLazyComponent Component={ChatHoverLg} />;
            case "drugContraIndications":
                return <DgLazyComponent Component={DrugContraIndications} />;
            case "drugIndications":
                return <DgLazyComponent Component={DrugIndications} />;
            case "drugRecommendedDose":
                return <DgLazyComponent Component={DrugRecommendedDose} />;
            case "drugSideEffects":
                return <DgLazyComponent Component={DrugSideEffects} />;
            case "sendScript":
                return <DgLazyComponent Component={PrescriptionPopup} />;
            case "freeTextNote":
                return <DgLazyComponent Component={FreeTextNote} />;
            case "bodyTemperature":
                return <DgLazyComponent Component={BodyTemperature} />;
            case "headCircumference":
                return <DgLazyComponent Component={HeadCircumference} />;
            case "height":
                return <DgLazyComponent Component={Height} />;
            case "pulseRate":
                return <DgLazyComponent Component={PulseRate} />;
            case "peakFlow":
                return <DgLazyComponent Component={PeakFlow} />;
            case "waistCircumference":
                return <DgLazyComponent Component={WaistCircumference} />;
            case "weight":
                return <DgLazyComponent Component={Weight} />;
            case "authorisePopup":
                return <DgLazyComponent Component={AuthorisePopup} />;
            case "finishPopup":
                return <DgLazyComponent Component={FinishPopup} />;
            case "reviewPopup":
                return <DgLazyComponent Component={ReviewPopup} />;
            case "history":
                return <DgLazyComponent Component={History} />;
            case "visitTemplate":
                return <DgLazyComponent Component={VisitTemplate} />;
            case "searchEntryType":
                return <DgLazyComponent Component={SearchEntryType} />;


            default:
                return null;
        }
    }, [props.name]);

    // Prevent background scrolling when the modal is open
    // Reference: https://stackoverflow.com/a/58290739
    useEffect(() => {
        if (props.isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
        return () => (document.body.style.overflow = "unset");
    }, [props.isOpen]);

    return (
        <Modal
            isOpen={props.isOpen}
      onRequestClose={() => {
        props.setIsOpen(false);
        props.setData({});
      }}
            contentLabel=""
            className="modal-container"
            overlayClassName="modal-overlay-container"
        >
            <div className="modal-content">{Content}</div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    isOpen: state.modal?.isOpen,
    name: state.modal?.name,
});

const mapDispatchToProps = (dispatch) => ({
    setIsOpen: (status) => dispatch(setIsOpen(status)),
    setName: (name) => dispatch(setName(name)),
  setData: (name) => dispatch(setData(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentFn);
