export { default as blogs } from "redux-blogs/build/reducer.js"; // import the blogs reducer from redux-blogs
export { default as utility } from "./utility";
export { default as quotes } from "./quote.reducer";
export { reducer as form } from "redux-form";
export { default as analytics } from "dg-analytics/build/reducer";
export { default as benefits } from "./benefits.reducer";
export { default as policyBenefits } from "./policyBenefits.reducer";
export { default as payment } from "./payment.reducer";
export { default as newsletters } from "./newsletter.reducer";
export { default as policyChanges } from "./policyChange.reducer";
export { default as renewals } from "./renew.reducer";
export { default as reviews } from "./reviews.reducer";
export { default as modal } from "./modal"; // Modals
