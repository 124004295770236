export const routes = [
    {
      url: '/admin/user',
      text: 'Users'
    },
    {
      url: '/admin/contact',
      text: 'Contacts'
    },
];
