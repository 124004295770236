import {
  CREATE_MEDICATION,
  UPDATE_MEDICATION,
  DELETE_MEDICATION,
  GET_MEDICATION,
  GET_ALL_MEDICATION,
  SET_CURRENT_MEDICATION,
} from "../actions/types";

const initialState = {
  currentMedication: {},
  medications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_MEDICATION:
    case CREATE_MEDICATION:
    case UPDATE_MEDICATION:
    case GET_MEDICATION:
      return {
        ...state,
        currentMedication: action.payload,
      };
    case DELETE_MEDICATION:
      return {
        ...state,
        currentMedication: {},
      };
    case GET_ALL_MEDICATION:
          return {
        ...state,
        medications: action.payload,
      };
    default:
      return state;
  }
}
