import {
  CREATE_VACCINE_BATCH,
  UPDATE_VACCINE_BATCH,
  DELETE_VACCINE_BATCH,
  GET_VACCINE_BATCH,
  GET_ALL_VACCINE_BATCH,
  SET_CURRENT_VACCINE_BATCH,
  RESET_VACCINE_BATCH,
} from "../actions/types";

const initialState = {
  currentVaccineBatch: {},
  vaccineBatches: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VACCINE_BATCH:
    case CREATE_VACCINE_BATCH:
    case UPDATE_VACCINE_BATCH:
    case GET_VACCINE_BATCH:
      return {
        ...state,
        currentVaccineBatch: action.payload,
      };
    case DELETE_VACCINE_BATCH:
      return {
        ...state,
        currentVaccineBatch: {},
      };
    case GET_ALL_VACCINE_BATCH:
      return {
        ...state,
        vaccineBatches: action.payload,
      };
    case RESET_VACCINE_BATCH:
      return {
        currentVaccineBatch: {},
        vaccineBatches: [],
      };
    default:
      return state;
  }
}
