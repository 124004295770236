import React from "react";
import { Link } from "react-router-dom";

export const info_page_data = [
    {
        title: "Disclaimer",
        body: (
            <React.Fragment>
                <p>
                    The information contained in this website is for general information purposes
                    only. The information is provided by City Financial Marketing Group Ltd. and
                    whilst we endeavour to keep the information up-to-date and correct, we make no
                    representations or warranties of any kind, expressed or implied, about the
                    completeness, accuracy, reliability, suitability or availability with respect to
                    the website or the information, products, services, or related graphics
                    contained on the website for any purpose. Any reliance you place on such
                    information is therefore strictly at your own risk.
                </p>
                <p>
                    In no event will we be liable for any loss or damage including without
                    limitation, indirect or consequential loss or damage, or any loss or damage
                    whatsoever arising from loss of data or profits arising out of, or in connection
                    with, the use of this website. You are responsible for the security of any data
                    which you transfer to or from our website.
                </p>
                <p>
                    Through this website you are able to link to other websites which are not under
                    the control of City Financial Marketing Group Ltd. We have no control over the
                    nature, content and availability of those sites. The inclusion of any links does
                    not necessarily imply a recommendation or endorse the views expressed within
                    them.
                </p>
                <p>Please click the link to see our cookies policy.</p>
            </React.Fragment>
        ),
    },
    {
        title: "Cookies Policy",
        body: (
            <React.Fragment>
                <p>
                    By accessing and using this website (the “Site”) you indicate that you accept
                    the use of cookies in the manner as described in this policy.
                </p>
                <h3>1.0 What are cookies?</h3>
                <p>
                    A cookie is small piece of data sent from a website and stored on your computer
                    or device. Almost all websites use cookies and they have several different
                    functions. The main purposes for cookies are to:
                </p>
                <ul>
                    <li>Allow the site to function correctly and to help keep it secure.</li>
                    <li>
                        Make the site easier to use by remembering information that you’ve entered,
                        to recognise you as a site user and to personalise your visit.
                    </li>
                    <li>Collect basic information about the way you have used the site.</li>
                </ul>
                <p>
                    The cookies may also allow CFM Group to record additional information on the
                    webpages you have visited and the contents of those webpages, any information
                    you input, your referring URL or IP address, your time of visit and related
                    session information, your requested page, web browser type, USR agent, status of
                    request (successful reply), cookies, number of bytes in the response, and query
                    string and any information or technical detail your browser is sending to us.
                </p>
                <h3>2.0 Do we use cookies and how do we use them?</h3>
                <p>
                    Yes. CFM Group uses this information to better understand website usage in the
                    aggregate so that we know what areas of our website users prefer. This
                    information is stored in log files and is used for statistical reporting and in
                    limited circumstances to prevent fraudulent claims.
                </p>
                <h3>3.0 What type of cookies do we use?</h3>
                <p>
                    For a detailed overview of the cookies on this website, please select the Cookie
                    Settings link the footer at any time. Here you will be able to see lists of
                    cookies by function including the name of the cookie, the data controller
                    associated with the cookie, a description of the cookie, and the cookie
                    retention time. Please note that while we strive to keep this list as accurate
                    as possible, the majority of cookies on this website are set by third party
                    scripts, and hence the list provided should be treated as indicative information
                    for the purpose of enabling a user to make an informed consent decision rather
                    than an exhaustive list of specific cookies.
                </p>
                <h4>3.1 Cookie classifications</h4>
                <p>
                    Cookies that are defined as “strictly necessary” are required to help keep data
                    safe. They allow you to move between different pages on our site. They also help
                    to keep your details secure
                </p>
                <p>
                    Cookies that are defined as “not strictly necessary”. This type of cookie helps
                    us improve the performance of our site. They tell us how pages are used, which
                    ones are commonly viewed and help us piece together a full picture of a typical
                    customer journey through the site. They also tell us if any errors occur
                </p>
                <p>
                    There are three types of cookies on our websites which fall under the above two
                    classifications:
                </p>
                <ul>
                    <li>Session Cookies</li>
                    <li>Persistent Cookies</li>
                    <li>Third Party Cookies</li>
                </ul>
                <h4>3.2 Session cookies</h4>
                <p>
                    A session cookie exists only while the user is reading and navigating the
                    website. Session cookies enable users to carry out essential tasks on our site,
                    without them the site will not function correctly. This type of cookie expires
                    as soon as you exit the browser you are using to view our website.
                </p>
                <h4>3.3 Persistent cookies</h4>
                <p>
                    A persistent cookie will outlast a user’s session. This could be used to record
                    a vital piece of information such as how the user initially came to this website
                    or to record details about the last browsing session on the site. For this
                    reason persistent cookies are also called tracking cookies.
                </p>
                <h4>3.4 Third party cookies</h4>
                <p>
                    Third party cookies are not related to or controlled by CFM Group. Third party
                    cookies are used by our business partners, e.g. Google Analytics. Google
                    Analytics tracking (and most web tracking software) uses cookies in order to
                    provide meaningful reports about site visitors.
                </p>
                <p>
                    CFM Group monitors this information to gather data about repeat visitors to our
                    site and their user experience. This helps us ensure that improvements are
                    consistently made to our sites based on your experiences. Google also reserve
                    the rights to the data collected.
                </p>
                <p>
                    The Google Analytics features implemented by CFM Group Insurance include
                    Remarketing and Double Click. These tools are used to advertise online, where
                    the information obtained is used to inform, optimise, and advertise based on
                    past visits to our site. These advertisements may be shown on sites across the
                    Internet.
                </p>
                <p>
                    This Site may link through to third party websites [including websites of
                    companies within the CFM Group] which may also use cookies over which CFM Group
                    has no control. CFM Group recommends that you check the relevant third parties’
                    privacy policies for information about any cookies that they may use.
                </p>
                <h3>4.0 How to disable your cookies</h3>
                <p>
                    If you don’t want to receive cookies, you can modify your preferences by
                    selecting the Cookie Settings link the footer of this website at any time.
                </p>
                <p>
                    Remember, if you refuse to accept cookies, certain critical elements of web
                    sites will not function correctly.
                </p>
                <h3>5.0 Changes to our cookie policy</h3>
                <p>
                    From time to time, we may need to change this cookie policy. For instance, we
                    may need to do so to reflect any changes in technology or legislation or our
                    data use practices. Any updates or changes that may affect how we use cookies or
                    how you as a user can manage cookies will appear in this Policy. We encourage
                    you to periodically review our Cookie Policy to determine the current policy in
                    force as the updated version applies to each use of this Site.
                </p>
                <h3>6.0 Contacting CFM Group</h3>
                <p>
                    If you have any questions, comments and requests regarding this cookie policy,
                    please contact us at&nbsp;<a href="mailto:gdpr@cfmgroup.ie">gdpr@cfmgroup.ie</a>
                </p>
                <h3>7.0 Additional information</h3>
                <p>
                    For more information on cookies and cookie legislation, please visit the
                    following sites:
                </p>
                <p>
                    <a
                        href="http://www.allaboutcookies.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.allaboutcookies.org
                    </a>
                </p>
                <p>
                    <a
                        href="http://www.youronlinechoices.eu/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.youronlinechoices.eu
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.wikipedia.org/wiki/HTTP_cookie"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.wikipedia.org/wiki/HTTP_cookie
                    </a>
                </p>
                <p>
                    <a
                        href="http://www.aboutcookies.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.aboutcookies.org
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.dataprotection.ie/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.dataprotection.ie
                    </a>
                </p>
            </React.Fragment>
        ),
    },
    {
        title: "Privacy Policy",
        body: (
            <React.Fragment>
                <h3>Privacy Statement</h3>
                <h3>GDPR Privacy Notice - CFM Group Limited</h3>
                <h4>Introduction</h4>
                <p>
                    City Financial Marketing Group Ltd T/A CFM Group, Insuremyvan.ie,
                    Insuremyhouse.ie, Coverinaclick.ie, Insuremycars.ie, Insuremyshop.ie,
                    Insuremyholiday.ie and Lifebroker.ie is regulated by the Central Bank of
                    Ireland. CFM Group Limited (hereafter CFM Group) is committed to protecting and
                    respecting your privacy. We wish to be transparent on how we process your data
                    and show you that we are accountable with the GDPR in relation to not only
                    processing your data but ensuring you understand your rights as a client.
                </p>
                <p>
                    It is the intention of this privacy statement to explain to you the information
                    practices of CFM Group in relation to the information we collect about you.
                </p>
                <p>For the purposes of the GDPR the data controller is:</p>
                <ul>
                    <li>-CFM Group Limited trading as outlined above</li>
                    <li>Located at 62A Terenure Road North, Terenure, Dublin 6W DW6 CF54</li>
                    <li>When we refer to 'we' it is CFM Group</li>
                </ul>
                <p>
                    Please read this Statement carefully as this sets out the basis on which any
                    personal data we collect from you, or that you provide to us, will be processed
                    by us.
                </p>
                <h4>Who are we?</h4>
                <p>
                    CFM Group is a 100% Irish owned Insurance Broker operating in Terenure, Dublin
                    6W. CFM Group staff are experts in van, motor, travel, home, commercial and life
                    products. CFM group offer quotations, advice and policies for clients to fit
                    their needs and employs over 80 staff to deal with all our clients.
                </p>
                <p>Our GPDR representative can be contacted directly here:</p>
                <ul>
                    <li>- Elaine Maher</li>
                    <li>- gdpr@cfmgroup.ie</li>
                    <li>- 01 660 6900</li>
                </ul>
                <h4>Purpose of processing your data</h4>
                <ul>
                    <li>- To provide insurance quotations to potential clients</li>
                    <li>
                        - To advise clients and potential clients of their insurance needs and
                        requirements
                    </li>
                    <li>
                        - To deal with claims on behalf of our clients and provide ongoing insurance
                        support and advice
                    </li>
                </ul>
                <h4>Why are we processing your data? Our legal basis. </h4>
                <p>
                    In order for us to provide you with insurance policy quotations CFM Group need
                    to collect personal data for the purpose of providing you with insurance
                    quotations and incept policy cover on your instruction. Our reason (lawful
                    reason) for processing your data under the GDPR is:
                </p>
                <p>
                    -Legal basis – CFM Group needs to process your data as this is necessary in
                    relation to a contract of insurance to which the individual has entered into or
                    because the individual has asked for something to be done so they can enter into
                    a contract. City Financial Marketing Group Ltd trades as CFM Group,
                    Insuremyvan.ie, Insuremyhouse.ie, Coverinaclick.ie, Insuremycars.ie,
                    Insuremyshop.ie, Insuremyholiday.ie and Lifebroker.ie and provides quotations,
                    advise and policies on a range of non life insurance products including home,
                    motor, commercial vehicle, travel, commercial insurance policies and life cover.
                </p>
                <p>
                    In any event, CFM Group are committed to ensuring that the information we
                    collect and use is appropriate for this purpose, and does not constitute an
                    invasion of your privacy.
                </p>
                <h4>How will CFM Group use the personal data it collects about me?</h4>
                <p>
                    CFM Group will process (collect, store and use) the information you provide in a
                    manner compatible with the EU’s General Data Protection Regulation (GDPR). We
                    will endeavor to keep your information accurate and up to date, and not keep it
                    for longer than is necessary.
                </p>
                <h4>Special Categories of personal data</h4>
                <p>
                    If we collect any special categories of personal data (e.g. health, religious
                    beliefs, racial, ethnic origin – financial information is not classified as
                    special categories of personal data) – we will ensure the below
                </p>
                <p>-We will obtain your explicit consent prior to collecting such data</p>
                <p>
                    -Please note in order for us to provide insurance quotations we must ask
                    questions in relation to convictions and prosecutions pending.
                </p>
                <h4>Who are we sharing your data with?</h4>
                <p>
                    CFM Group (including all trading names above mentioned) is a regulated insurance
                    intermediary appointed by insurance companies to provide clients with quotations
                    and policies of insurance. We collect data required for the provision of
                    contract terms and this information is shared with our insurance company and
                    underwriting partners who provide the insurance premium for the risk being
                    proposed to them.
                </p>
                <p>
                    We may pass your personal data on to third-party service providers contracted to
                    CFM Group in the course of dealing with you. Any third parties that we may share
                    your data with are obliged to keep your details securely, and to use them only
                    to fulfil the service they provide on your behalf. When they no longer need your
                    data to fulfil this service, they will dispose of the details in line with CFM
                    Group procedures. In certain cases where you the client appoint a third party to
                    act on your behalf (loss assessors for example) we will share data with them on
                    your written request to do so.
                </p>
                <p>
                    If we wish to pass your sensitive personal data onto a third party we will only
                    do so once we have obtained your explicit consent, unless we are legally
                    required to do otherwise.
                </p>
                <p>
                    We have issued all our third party processors with a Data Processor checklist
                    asking them GDPR specific questions.
                </p>
                <p>
                    If we transfer personal data to a third party or outside the EU we as the data
                    controller will ensure the recipient (processor or another controller) has
                    provided the appropriate safeguards and on condition that enforceable data
                    subject rights and effective legal remedies for you the data subject are
                    available.
                </p>
                <h4>Data Subjects Rights:</h4>
                <p>
                    CFM Group facilitates you, our clients, rights in line with our data protection
                    policy and the subject access request procedure. This is available on request.
                    For any GDPR related queries please email our appointed representative Elaine
                    Maher – gdpr@cfmgroup.ie
                </p>
                <h4>Your rights as a data subject</h4>
                <p>
                    At any point while we are in possession of or processing your personal data,
                    you, the data subject, have the following rights:
                </p>
                <ul>
                    <li>
                        -Right of access – you have the right to request a copy of the information
                        that we hold about you.
                    </li>
                    <li>
                        -Right of rectification – you have a right to correct data that we hold
                        about you that is inaccurate or incomplete.
                    </li>
                    <li>
                        -Right to be forgotten – in certain circumstances you can ask for the data
                        we hold about you to be erased from our records.
                    </li>
                    <li>
                        -Right to restriction of processing – where certain conditions apply to have
                        a right to restrict the processing.
                    </li>
                    <li>
                        -Right of portability – you have the right to have the data we hold about
                        you transferred to another organisation.
                    </li>
                    <li>
                        -Right to object – you have the right to object to certain types of
                        processing such as direct marketing.
                    </li>
                    <li>
                        -Right to object to automated processing, including profiling – you also
                        have the right to be subject to the legal effects of automated processing or
                        profiling.
                    </li>
                    <li>
                        -Right to judicial review: in the event that CFM Group refuses your request
                        under rights of access, we will provide you with a reason as to why.
                    </li>
                    <p>
                        All of the above requests will be forwarded on should there be a third party
                        involved as we have indicated in the processing of your personal data.
                    </p>
                </ul>
                <h4>Retention of personal data</h4>
                <p>
                    Data will not be held for longer than is necessary for the purpose(s) for which
                    they were obtained. CFM Group will process personal data in accordance with our
                    retention schedule. This retention schedule has been governed by our regulatory
                    body and our internal governance.
                </p>
                <h4>Complaints</h4>
                <p>
                    In the event that you wish to make a complaint about how your personal data is
                    being processed by CFM Group or how your complaint has been handled, you have
                    the right to lodge a complaint directly with the supervisory authority and CFM
                    Group’s GDPR appointed Representative Elaine Maher gdpr@cfmgroup.ie
                </p>
                <h4>Failure to provide further information</h4>
                <p>
                    If we are collecting your data for an insurance contract/ quotation and you
                    cannot provide this data the consequences of this could mean the contract cannot
                    be completed, quotation cannot be provided or details are incorrect.
                </p>
                <h4>Profiling - automatic decision making</h4>
                <p>
                    As an insurance broker we will use profiling in our business. The main
                    categories of profiling used in CFM Group are:
                </p>
                <p>a) Profiling for marketing purposes.</p>
                <p>
                    When we seek to contact you about other services, as outlined above we run
                    automated queries on our computerised data base to establish the suitability of
                    proposed products or services to your needs.
                </p>
                <p>b) Establishing affordability and providing quotations for insurance services</p>
                <h4>Additional Processing</h4>
                <p>
                    If we intend to further process your personal data for a purpose other than for
                    which the data was collected, we will provide this information prior to
                    processing this data.
                </p>
                <h4>Contact Us and Updates to this Notice</h4>
                <p>
                    Your privacy is important to us. If you have any comments or questions regarding
                    this statement, please contact Elaine Maher on 01 660 6900, email:
                    gdpr@cfmgroup.ie or visit www.cfmgroup.ie/gdpr/
                </p>
                <p>
                    This notice will be reviewed annually to ensure compliance with the GDPR and in
                    line with all our policies and procedures.
                </p>
                <p>
                    Please click the link to see our{" "}
                    <Link to="/cookies-policy">cookies policy</Link>
                </p>
            </React.Fragment>
        ),
    },
    {
        title: "Assumptions",
        body: (
            <React.Fragment>
                <p>
                    In order to avail of our online car insurance quotation you must comply with the
                    following assumptions.
                </p>
                <h3>Your Details</h3>
                <ol>
                    <li>You confirm you have read and agree with our terms of business.</li>
                    <li>You own the car and it is registered in your name.</li>
                    <li>
                        Where previously insured on another policy, you have not had a gap in cover.
                    </li>
                    <li>All drivers are resident and in Ireland for the past 3 years.</li>
                    <li>
                        You and all drivers have had no accidents and are claims free in the past 5
                        years.
                    </li>
                    <li>You and all drivers have no physical disability or infirmity.</li>
                    <li>
                        You and all drivers have no previous convictions for any motor offence and
                        no prosecutions are pending.
                    </li>
                    <li>
                        You and all drivers have never had an insurance policy cancelled or declined
                        cover.
                    </li>
                    <li>
                        All drivers hold a full licence issued by an EU country, or a learner
                        permit/Provisional licence issued in Ireland.
                    </li>
                    <li>You confirm the car is only for social domestic and private use.</li>
                    <li>You confirm the car is not being used for commercial purposes.</li>
                </ol>
                <h3>The Car</h3>
                <p>In respect of the car to be insured, you confirm the following is true.</p>
                <ol>
                    <li>It is taxed privately in the Republic of Ireland.</li>
                    <li>It has not been modified.</li>
                    <li>It has not been imported and is a right-hand drive vehicle.</li>
                    <li>
                        It does not have non-standard audio equipment or a non-standard car phone.
                    </li>
                    <li>It is normally kept at your home address.</li>
                    <li>It is not more than 20 years old.</li>
                </ol>
                <p>
                    If you cannot agree to the above assumptions don’t worry we will still offer you
                    a great car insurance quote but you need to call us on 01 231 9310 to get a
                    quote from one of our customer service team.
                </p>
            </React.Fragment>
        ),
    },
];
