import {
    CREATE_SERVICE_REQUEST,
    UPDATE_SERVICE_REQUEST,
    DELETE_SERVICE_REQUEST,
    GET_SERVICE_REQUEST,
    GET_ALL_SERVICE_REQUEST,
    SET_CURRENT_SERVICE_REQUEST,
} from "../actions/types";

const initialState = {
    currentServiceRequest: {},
    serviceRequests: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_SERVICE_REQUEST:
        case CREATE_SERVICE_REQUEST:
        case UPDATE_SERVICE_REQUEST:
        case GET_SERVICE_REQUEST:
            return {
                ...state,
                currentServiceRequest: action.payload,
            };
        case DELETE_SERVICE_REQUEST:
            return {
                ...state,
                currentServiceRequest: {},
            };
        case GET_ALL_SERVICE_REQUEST:
            return {
                ...state,
                serviceRequests: action.payload,
            };
        default:
            return state;
    }
}
