import {
  CREATE_PRACTITIONER_ROLE,
  UPDATE_PRACTITIONER_ROLE,
  DELETE_PRACTITIONER_ROLE,
  GET_PRACTITIONER_ROLE,
  GET_ALL_PRACTITIONER_ROLE,
  SET_CURRENT_PRACTITIONER_ROLE,
} from "../actions/types";

const initialState = {
  currentPractitionerRole: {},
  practitionerRoles: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PRACTITIONER_ROLE:
    case CREATE_PRACTITIONER_ROLE:
    case UPDATE_PRACTITIONER_ROLE:
    case GET_PRACTITIONER_ROLE:
      return {
        ...state,
        currentPractitionerRole: action.payload,
      };
    case DELETE_PRACTITIONER_ROLE:
      return {
        ...state,
        currentPractitionerRole: {},
      };
    case GET_ALL_PRACTITIONER_ROLE:
      return {
        ...state,
        practitionerRoles: action.payload,
      };
    default:
      return state;
  }
}
