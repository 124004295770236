import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

//import ProtectedRoute from "./components/protectedRoute.component";
import history from "./history";
import ScrollToTop from "./components/helper/scrollToTop";
import Loading from "./components/loading/loading";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";

// Asset files
import { routes as adminRoutes } from "./assets/files/adminRoutes";

// Assets
import { routes } from "./assets/files/adminRoutes";
import { info_page_data } from "./assets/copy/infoPages";

//Pages
const Home = lazy(() => import("./pages/Home"));
const CarInsurance = lazy(() => import("./pages/carInsurance"));
const YoungDrivers = lazy(() => import("./pages/youngDrivers"));
const DriverLicenceRequirements = lazy(() => import("./pages/driverLicenceRequirements"));
const LearnerDrivers = lazy(() => import("./pages/learnerDrivers"));
const CustomerSupport = lazy(() => import("./pages/customerSupport"));
const EmergencyBreakdown = lazy(() => import("./pages/emergencyBreakdown"));
const PolicyDocuments = lazy(() => import("./pages/policyDocuments"));
const Contact = lazy(() => import("./pages/contact"));
const ReturningCitizens = lazy(() => import("./pages/returningCitizens/page"));
const BlemishedDrivers = lazy(() => import("./pages/blemishedDrivers/page"));
const NewResidents = lazy(() => import("./pages/newResidents/page"));
const QuotePage = lazy(() => import("./pages/quotePage/page"));
const QuoteSummary = lazy(() => import("./pages/quoteSummary/page"));
const QuotePaymentSuccess = lazy(() => import("./pages/quotePaymentSuccess/page"));
const QuotePaymentFailure = lazy(() => import("./pages/quotePaymentFailure/page"));

// const { RenewCarInsurance } =lazy(()=>import( "./pages/renewCarInsurance"));
// const { UIKit } =lazy(()=>import( "./pages/UIKit"));
const FooterTemplate = lazy(() => import("./pages/footer_template"));

const GetAQuote = lazy(() => import("./pages/getAQuote"));
const PaymentOptions = lazy(() => import("./pages/payment/paymentOptions.component"));
const PayByDebit = lazy(() => import("./pages/payment/payByDebit.component.js"));
const SelectedAssumptions = lazy(() => import("./pages/payment/selectedAssumptions.component.js"));
const Thankyou = lazy(() => import("./pages/payment/fullThankYou.component.js"));
const RetrieveAQuote = lazy(() => import("./pages/retrieveAQuote"));

const RenewPolicy = lazy(() => import("./pages/renewPolicy"));
const RenewSuccess = lazy(() => import("./pages/payment/renewSuccess"));
const RenewFailed = lazy(() => import("./pages/payment/renewFailed"));

// Blogs
const BlogList = lazy(() => import("./pages/blogs/blogList"));
const BlogPost = lazy(() => import("./pages/blogs/blogPost"));

//Testing Generalised list with policy [Admin]
const AdminPolicyList = lazy(() => import("./pages/admin/policy/list"));
const AdminPolicyView = lazy(() => import("./pages/admin/policy/view"));

//Newsletter
const AdminNewsletterList = lazy(() => import("./pages/admin/newsletter/list"));
const AdminNewsletterView = lazy(() => import("./pages/admin/newsletter/view"));

// Renewals [Admin]
const AdminRenewalsList = lazy(() => import("./pages/admin/renewals/list"));
const AdminViewRenewal = lazy(() => import("./pages/admin/renewals/view"));

// Contacts [Admin]
const AdminContactList = lazy(() => import("./pages/admin/contacts/list"));
const AdminContactView = lazy(() => import("./pages/admin/contacts/view"));

// Users [Admin]
const AdminUserList = lazy(() => import("./pages/admin/users/list"));
const AdminUserNew = lazy(() => import("./pages/admin/users/create"));
const AdminUserEdit = lazy(() => import("./pages/admin/users/edit"));

// Quote [Admin]
const AdminQuoteList = lazy(() => import("./pages/admin/quote/list"));
const AdminQuoteUpdate = lazy(() => import("./pages/admin/quote/update"));
const AdminQuoteOptions = lazy(() => import("./pages/admin/quote/options"));
const AdminQuoteOptionDetails = lazy(() => import("./pages/admin/quote/optionDetails"));
const AdminQuoteEmails = lazy(() => import("./pages/admin/quote/email"));
const Helmet = lazy(() => import("./components/helmet/helmet"));
const seo = lazy(() => import("./assets/files/seo.json"));

// Import from packages
const Sidebar = lazy(() => import("dg-admin/build/Sidebar"));
const ProtectedRoute = lazy(() => import("dg-admin/build/ProtectedRoute"));

// Admin Pages
const Login = lazy(() => import("./pages/admin/login/login"));
const PasswordReset = lazy(() => import("./pages/admin/login/passwordReset"));
const AdminPostListPage = lazy(() => import("redux-blogs/build/AdminPostListPage"));
const AdminEditPostPage = lazy(() => import("redux-blogs/build/AdminEditPostPage"));
const AdminNewPostPage = lazy(() => import("redux-blogs/build/AdminNewPostPage"));

// CMS [Admin]
const CMSList = lazy(() => import("./pages/admin/cms/list"));
const EditCMS = lazy(() => import("./pages/admin/cms/edit"));

function Router() {
    return (
        <Suspense fallback={<Loading />}>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/blog" exact element={<BlogList />} />
                <Route path="/blog/post/:id" exact element={<BlogPost />} />
                <Route path="/car-insurance" exact element={<CarInsurance />} />
                <Route path="/car-insurance/young-drivers" exact element={<YoungDrivers />} />
                <Route path="/driver-licence-requirements" exact element={<DriverLicenceRequirements />} />
                <Route path="/car-insurance/learner-drivers" exact element={<LearnerDrivers />} />
                {/* <Route path="/renew-car-insurance" exact element={<RenewCarInsurance />} /> */}
                <Route path="/renew-your-policy" exact element={<RenewPolicy />} />
                <Route path="/renew-your-policy/success" exact element={<RenewSuccess />} />
                <Route path="/renew-your-policy/failed" exact element={<RenewFailed />} />
                <Route path="/support" exact element={<CustomerSupport />} />
                <Route path="/support/emergency-breakdown" exact element={<EmergencyBreakdown />} />
                {/*<Route path="/support/policy-documents" exact element={<PolicyDocuments />} />*/}
                <Route
                    path="/support/policy-documents/request-to-change"
                    exact
                    element={<PolicyDocuments />}
                />
                <Route path="/contact" exact element={<Contact />} />
                <Route path="/get-a-quote/payment-options" exact element={<PaymentOptions />} />
                <Route path="/pay-by-direct-debit" exact element={<PayByDebit />} />
                <Route path="/selected-assumptions" exact element={<SelectedAssumptions />} />
                <Route path="/thankyou" exact element={<Thankyou />} />
                <Route path="/get-a-quote" exact element={<GetAQuote />} />
                <Route path="/get-a-quote/:id" exact element={<GetAQuote />} />
                <Route path="/retrieve-a-quote" exact element={<RetrieveAQuote />} />
                <Route path="/retrieve-a-quote/:webReference" exact element={<RetrieveAQuote />} />
                <Route path="/returning-citizens" exact element={<ReturningCitizens />} />
                <Route path="/blemished-drivers" exact element={<BlemishedDrivers />} />
                <Route path="/new-residents" exact element={<NewResidents />} />
                <Route path="/quote-page" exact element={<QuotePage />} />
                <Route path="/get-a-quote/summary" exact element={<QuoteSummary />} />
                <Route
                    path="/get-a-quote/payment/success"
                    exact
                    element={<QuotePaymentSuccess />}
                />
                <Route
                    path="/get-a-quote/payment/failure"
                    exact
                    element={<QuotePaymentFailure />}
                />

                <Route
                    path="/disclaimer"
                    exact
                    element={
                        <FooterTemplate
                            title={info_page_data[0].title}
                            content={info_page_data[0].body}
                        />
                    }
                />
                <Route
                    path="/cookie-policy"
                    exact
                    element={
                        <FooterTemplate
                            title={info_page_data[1].title}
                            content={info_page_data[1].body}
                        />
                    }
                />
                <Route
                    path="/privacy-policy"
                    exact
                    element={
                        <FooterTemplate
                            title={info_page_data[2].title}
                            content={info_page_data[2].body}
                        />
                    }
                />
                <Route
                    path="/assumptions"
                    exact
                    element={
                        <FooterTemplate
                            title={info_page_data[3].title}
                            content={info_page_data[3].body}
                        />
                    }
                />
                <Route path="/admin" exact element={<Login />} />
                <Route path="/verifypasswordreset" exact element={<PasswordReset />} />

                <Route
                    path="/admin/posts"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminPostListPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent Component={Sidebar} routes={adminRoutes} />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/posts/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminNewPostPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent Component={Sidebar} routes={adminRoutes} />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/posts/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={(routeProps) => (
                                <AdminEditPostPage
                                    {...routeProps}
                                    history={history}
                                    sidebar={
                                        <DgLazyComponent Component={Sidebar} routes={adminRoutes} />
                                    }
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/admin/user"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminUserList} />
                    }
                />
                <Route
                    path="/admin/user/create"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminUserNew} />
                    }
                />
                <Route
                    path="/admin/user/edit/:id"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminUserEdit} />
                    }
                />

                <Route
                    path="/admin/contact"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminContactList} />
                    }
                />
                <Route
                    path="/admin/contacts/edit/:id"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminContactView} />
                    }
                />
                <Route
                    path="/admin/cms"
                    exact
                    element={<DgLazyComponent Component={ProtectedRoute} component={CMSList} />}
                />
                <Route
                    path="/admin/cms/:id"
                    exact
                    element={<DgLazyComponent Component={ProtectedRoute} component={EditCMS} />}
                />
                <Route
                    path="/admin/quotes"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminQuoteList} />
                    }
                />
                <Route
                    path="/admin/quotes/:id"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminQuoteUpdate} />
                    }
                />
                <Route
                    path="/admin/quotes/:id/options"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminQuoteOptions} />
                    }
                />
                <Route
                    path="/admin/quotes/:id/options/:detailsId"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminQuoteOptionDetails}
                        />
                    }
                />
                <Route
                    path="/admin/quotes/:id/emails"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminQuoteEmails} />
                    }
                />
                <Route
                    path="/admin/policy"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminPolicyList} />
                    }
                />
                <Route
                    path="/admin/policy/:id"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminPolicyView} />
                    }
                />
                <Route
                    path="/admin/newsletter"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminNewsletterList}
                        />
                    }
                />
                <Route
                    path="/admin/newsletter/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminNewsletterView}
                        />
                    }
                />
                <Route
                    path="/admin/renewals"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminRenewalsList} />
                    }
                />
                <Route
                    path="/admin/renewals/:id"
                    exact
                    element={
                        <DgLazyComponent Component={ProtectedRoute} component={AdminViewRenewal} />
                    }
                />
                            {/* <Route path="*" element={<Navigate to="/" replace />} />*/}
            </Routes>
        </Suspense>
    );
}
export default Router;
