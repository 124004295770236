import {
    CREATE_DRUG_PRODUCT,
    UPDATE_DRUG_PRODUCT,
    DELETE_DRUG_PRODUCT,
    GET_DRUG_PRODUCT,
    GET_EXPANDED_DRUG_PRODUCT,
    GET_ALL_DRUG_PRODUCT,
    SET_CURRENT_DRUG_PRODUCT,
} from "../../actions/types";

const initialState = {
    currentDrugProduct: {},
    drugProducts: [],
    patientWarnings: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_DRUG_PRODUCT:
        case CREATE_DRUG_PRODUCT:
        case UPDATE_DRUG_PRODUCT:
        case GET_DRUG_PRODUCT:
        case GET_EXPANDED_DRUG_PRODUCT:
            return {
                ...state,
                currentDrugProduct: action.payload,
            };
        case DELETE_DRUG_PRODUCT:
            return {
                ...state,
                currentDrugProduct: {},
            };
        case GET_ALL_DRUG_PRODUCT:
            return {
                ...state,
                drugProducts: action.payload,
            };
        default:
            return state;
    }
}
