import {
  CREATE_FLOWCHART,
  UPDATE_FLOWCHART,
  DELETE_FLOWCHART,
  GET_FLOWCHART,
  GET_ALL_FLOWCHART,
  SET_CURRENT_FLOWCHART,
} from "../actions/types";

const initialState = {
  currentFlowchart: {},
  flowcharts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_FLOWCHART:
    case CREATE_FLOWCHART:
    case UPDATE_FLOWCHART:
    case GET_FLOWCHART:
      return {
        ...state,
        currentFlowchart: action.payload,
      };
    case DELETE_FLOWCHART:
      return {
        ...state,
        currentFlowchart: {},
      };
    case GET_ALL_FLOWCHART:
          return {
        ...state,
        flowcharts: action.payload,
      };
    default:
      return state;
  }
}
