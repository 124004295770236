import {
    CREATE_LOCATION,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    GET_LOCATION,
    GET_ALL_LOCATION,
    SET_CURRENT_LOCATION,
} from "../actions/types";

const initialState = {
    currentClinic: {},
    locations: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_LOCATION:
        case CREATE_LOCATION:
        case UPDATE_LOCATION:
        case GET_LOCATION:
            return {
                ...state,
                currentClinic: action.payload,
            };
        case DELETE_LOCATION:
            return {
                ...state,
                currentClinic: {},
            };
        case GET_ALL_LOCATION:
            return {
                ...state,
                locations: action.payload,
            };
        default:
            return state;
    }
}
