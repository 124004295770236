import {
    CREATE_MEDICATION_REQUEST,
    UPDATE_MEDICATION_REQUEST,
    DELETE_MEDICATION_REQUEST,
    GET_MEDICATION_REQUEST,
    GET_ALL_MEDICATION_REQUEST,
    SET_CURRENT_MEDICATION_REQUEST,
    GET_DRUG_LAST_MEDICATION_REQUEST,
} from "../actions/types";

const initialState = {
    currentMedicationRequest: {},
    drugLastMedicationRequest: {},
    medicationRequests: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_MEDICATION_REQUEST:
        case CREATE_MEDICATION_REQUEST:
        case UPDATE_MEDICATION_REQUEST:
        case GET_MEDICATION_REQUEST:
            return {
                ...state,
                currentMedicationRequest: action.payload,
            };
        case GET_DRUG_LAST_MEDICATION_REQUEST:
            return {
                ...state,
                drugLastMedicationRequest: action.payload,
            };
        case DELETE_MEDICATION_REQUEST:
            return {
                ...state,
                currentMedicationRequest: {},
            };
        case GET_ALL_MEDICATION_REQUEST:
            return {
                ...state,
                medicationRequests: action.payload,
            };
        default:
            return state;
    }
}
