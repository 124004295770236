import { SET_IS_POLICY_BENEFITS_OPEN, SET_POLICY_BENEFIT } from "../actions/types";

//Currently using default benefit to ensure sizing of left container card is consistent. There has got to be a much cleaner way of doing this. Using min-width could mess up responsivity. I will pray to the god of js for many years in penance for this sin.

const initialState = {
  isBenefitsOpen: true,
  benefit: {
    index: 0,
    title: "Policy Benefits",
    paragraphs: [
      "As each car insurance policy is different, insurance companies will have a list of various car insurance benefits and within these benefits the level of cover will also vary! For example, with Breakdown Assistance some insurance companies may also include Home Start Cover at no extra charge. ",
      "At Coverinaclick.ie we strive to get you only the benefits and cover you want at the lowest possible price, giving you total control of your car insurance policy!",
    ],
    default: "",
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_POLICY_BENEFIT:
      return {
        ...state,
        benefit: action.payload,
      };
    case SET_IS_POLICY_BENEFITS_OPEN:
      return {
        ...state,
        isBenefitsOpen: action.payload,
      };
    default:
      return state;
  }
}
