import React from "react";
import Html from "./Html";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

// Library Styles
import "./styles/App.scss";
import "dg-forms/build/index.css";
import "dg-cms/build/index.css";
import "dg-admin/build/index.css";
import "dg-utils/build/index.css";
import "dg-gallery/build/index.css";
import "dg-admin/build/index.css";
import "dg-analytics/build/index.css";
import "./components/sweetAlert2/modal.scss";

// Components
import Router from "./router";
import Modal from "./components/modal/component";
import LoadingOverlay from "./components/loading/LoadingOverlay";

// Define the initial state
const preloadedState = store.getState();

function App({ assets, helmet, server, currentLocation }) {
  return (
    <Provider store={store}>
      {server ? (
        <Html
          assets={assets}
          helmet={helmet}
          preloadedState={preloadedState}
          server
        >
          <StaticRouter location={currentLocation}>
            <Router />
            <Modal />
            <LoadingOverlay />
          </StaticRouter>
        </Html>
      ) : (
        <BrowserRouter>
          <Router />
          <Modal />
          <LoadingOverlay />
        </BrowserRouter>
      )}
    </Provider>
  );
}

export default App;
