import {
  CREATE_IMMUNIZATION,
  UPDATE_IMMUNIZATION,
  DELETE_IMMUNIZATION,
  GET_IMMUNIZATION,
  GET_ALL_IMMUNIZATION,
  SET_CURRENT_IMMUNIZATION,
} from "../actions/types";

const initialState = {
  currentImmunization: {},
  immunizations: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_IMMUNIZATION:
    case CREATE_IMMUNIZATION:
    case UPDATE_IMMUNIZATION:
    case GET_IMMUNIZATION:
      return {
        ...state,
        currentImmunization: action.payload,
      };
    case DELETE_IMMUNIZATION:
      return {
        ...state,
        currentImmunization: {},
      };
    case GET_ALL_IMMUNIZATION:
          return {
        ...state,
        immunizations: action.payload,
      };
    default:
      return state;
  }
}
