import { 
    DELETE_NEWSLETTER, 
    GET_NEWSLETTERS, 
    GET_NEWSLETTER,
    NEWSLETTER_SUBMIT
  } from '../actions/types';
  
  const initialState = {
    newsletter: {},
    newsletters: [],
    currentNewsletter: {},
    ok: true,
    message: '',
    errors: []
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case NEWSLETTER_SUBMIT:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          newsletter: action.payload.newsletter
        }
      case GET_NEWSLETTER:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          currentNewsletter: action.payload.newsletter
        }
      case GET_NEWSLETTERS:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          newsletters: action.payload.newsletters
        }
      case DELETE_NEWSLETTER:
        return {
          ...state,
          ok: action.payload.ok,
          message: action.payload.message,
          errors: action.payload.errors,
          currentNewsletter: action.payload.newsletter
        }
      default:
        return state
    }
  }