import {
    CREATE_PRACTITIONER,
    UPDATE_PRACTITIONER,
    DELETE_PRACTITIONER,
    GET_PRACTITIONER,
    GET_ALL_PRACTITIONER,
    SET_CURRENT_PRACTITIONER,
    SET_LOGGED_IN_PRACTITIONER,
} from "../actions/types";

const initialState = {
    currentPractitioner: {},
    loggedInPractitioner: {},
    practitioners: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_PRACTITIONER:
        case CREATE_PRACTITIONER:
        case UPDATE_PRACTITIONER:
        case GET_PRACTITIONER:
            return {
                ...state,
                currentPractitioner: action.payload,
            };
        case SET_LOGGED_IN_PRACTITIONER:
            return {
                ...state,
                loggedInPractitioner: action.payload,
            };
        case DELETE_PRACTITIONER:
            return {
                ...state,
                currentPractitioner: {},
            };
        case GET_ALL_PRACTITIONER:
            return {
                ...state,
                practitioners: action.payload,
            };
        default:
            return state;
    }
}
