import {
  CREATE_VACCINE_TYPE,
  UPDATE_VACCINE_TYPE,
  DELETE_VACCINE_TYPE,
  GET_VACCINE_TYPE,
  GET_ALL_VACCINE_TYPE,
  SET_CURRENT_VACCINE_TYPE,
} from "../actions/types";

const initialState = {
  currentVaccineType: {},
  vaccineTypes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VACCINE_TYPE:
    case CREATE_VACCINE_TYPE:
    case UPDATE_VACCINE_TYPE:
    case GET_VACCINE_TYPE:
      return {
        ...state,
        currentVaccineType: action.payload,
      };
    case DELETE_VACCINE_TYPE:
      return {
        ...state,
        currentVaccineType: {},
      };
    case GET_ALL_VACCINE_TYPE:
      return {
        ...state,
        vaccineTypes: action.payload,
      };
    default:
      return state;
  }
}
