import { 
    SET_IS_LOADING,
    SET_IS_MOBILE_MENU_OPEN
 } from '../actions/types';

const initialState = {
    isMobileMenuOpen: false,
    ok: false,
    message: '',
    errors: [],
    isLoading:false
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_IS_MOBILE_MENU_OPEN: 
            return {
                ...state,
                isMobileMenuOpen: action.payload
            }
        case SET_IS_LOADING: 
            return {
                ...state,
                isLoading: action.payload
            }
        default: 
            return state;
    }
}